@import '../../assets/style/style.css';
#contact-us-desktop {
  color: white;
  position: relative;
  padding-top: 135px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 120px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #contact-us-desktop .line-wrapper {
    display: none;
  }
}
#contact-us-desktop .title-container {
  position: relative;
  top: -20px;
  width: 23%;
  text-align: center;
}
#contact-us-desktop .title-container img {
  width: 130px;
  height: 111px;
}
#contact-us-desktop .title-container > h1 {
  font-size: 2.92vw;
  margin: 0;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
#contact-us-desktop .content-container {
  height: 380px;
  width: 85%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

#contact-us-mobile {
  display: none;
}

@media (max-width: 499px) {
  #contact-us-desktop {
    display: none;
  }
  #contact-us-mobile {
    display: block;
  }
  #contact-us-mobile .line-wrapper {
    display: none !important;
  }
  #contact-us-mobile .contactus-container .iqmbtT {
    margin-top: 30px;
  }
  #contact-us-mobile .contactus-container .content-container {
    margin-bottom: 0px;
  }
  #contact-us-mobile .contactus-container .content-container h1 {
    margin-bottom: 0px;
  }
  #contact-us-mobile .contactus-container img {
    width: 24px;
    height: 24px;
  }
}
.contactus-container {
  position: relative;
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contactus-container img {
  width: 130px;
  height: 111px;
}
.contactus-container .intestazione-container {
  display: none;
}
.contactus-container .map-container {
  display: none;
}
.contactus-container .form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.contactus-container .form-container > form {
  height: 100%;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.contactus-container .form-container > form .name-field, .contactus-container .form-container > form .contacts-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contactus-container .form-container > form .name-field .group, .contactus-container .form-container > form .contacts-field .group {
  width: 48%;
  padding: 0;
}
.contactus-container .form-container > form .page-button {
  width: 58%;
  margin-top: 48px !important;
}
.contactus-container .form-container > form .group {
  width: 100%;
  margin-bottom: 45px;
  position: relative;
}
.contactus-container .form-container > form .group > input {
  font-size: 16px;
  z-index: 10;
  border: 0;
  border-bottom: 1px solid #9c9c9c;
  color: white;
  padding: 15px 0;
  background: transparent;
  width: 100%;
  transition: border-bottom 0.2s ease-in-out;
}
.contactus-container .form-container > form .group > input:focus {
  outline: none;
  border-bottom: 3px solid white;
}
.contactus-container .form-container > form .group > textarea {
  font-size: 16px;
  z-index: 10;
  border: 0;
  border-bottom: 1px solid #9c9c9c;
  color: white;
  padding: 15px 0;
  background: transparent;
  width: 100%;
  transition: border-bottom 0.2s ease-in-out;
  resize: none;
}
.contactus-container .form-container > form .group > textarea:focus {
  outline: none;
  border-bottom: 3px solid white;
}
.contactus-container .form-container > form .group > label {
  z-index: 7;
  opacity: 0.7;
  position: absolute;
  top: 25%;
  animation: notOnFocus 0.3s ease-in-out;
}
.contactus-container .form-container > form .group > label.focus {
  opacity: 1;
  top: 25%;
  animation: onFocus 0.3s ease-in-out forwards;
}

@keyframes onFocus {
  0% {
    top: 25%;
  }
  100% {
    top: -30%;
  }
}
@keyframes notOnFocus {
  0% {
    top: -30%;
  }
  100% {
    top: 25%;
  }
}
@media (max-width: 499px) {
  .contactus-container {
    width: 100%;
  }
  .contactus-container img {
    display: none;
  }
  .contactus-container .intestazione-container {
    position: relative;
    display: block;
    top: 45px;
    padding: 15px 30px;
    border-bottom: 3px solid white;
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
  }
  .contactus-container .intestazione-container > h1 {
    font-weight: normal;
    color: white;
    text-transform: uppercase;
    font-size: 19px;
    padding: 0px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 46.59px;
    margin: 0;
    font-weight: bold;
  }
  .contactus-container .map-container {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    z-index: 2;
  }
  .contactus-container .map-container .map {
    display: flex;
  }
  .contactus-container .form-container {
    padding: 40px 30px 170px 30px;
  }
  .contactus-container .form-container > form .group {
    color: white;
    margin: 13px 0px;
  }
  .contactus-container .form-container > form .name-field, .contactus-container .form-container > form .contacts-field {
    flex-direction: column;
  }
  .contactus-container .form-container > form .name-field .group, .contactus-container .form-container > form .contacts-field .group {
    width: 100%;
  }
  .contactus-container .form-container > form .page-button {
    width: 70% !important;
  }
}