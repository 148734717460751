@import '../../../assets/style/style.css';
#engineering-page {
  width: 100%;
  overflow-x: hidden;
  text-align: left;
  color: white;
  margin-bottom: 225px;
  position: relative;
  top: 130px;
}
#engineering-page .mouse-container {
  top: 120%;
}
#engineering-page .content {
  flex-direction: column;
  padding: 20px 200px 0px 200px;
  position: relative;
  overflow-y: hidden;
}
#engineering-page .content .background {
  text-align: center;
}
#engineering-page .content .background-mobile {
  display: none;
}
#engineering-page .content .content-description {
  position: relative;
  top: -25px;
  opacity: 0;
}
#engineering-page .content .content-description.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#engineering-page .content .content-description .title-content {
  font-weight: bold;
  font-size: 2.92vw;
  margin: 50px 0px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  text-align: center;
}
#engineering-page .content .content-description .engineering-content {
  position: relative;
  margin-bottom: 40px;
}
#engineering-page .content .content-description .bullet-section {
  font-size: 1.2vw;
}
#engineering-page .content .content-description .bullet-section .bullet-title {
  font-size: 2.27vw;
}
#engineering-page .content .content-description .bullet-section .bullet-content {
  margin-top: 50px;
}
#engineering-page .content .content-description .bullet-section .bullet-content .bullet-list {
  position: relative;
  top: 40px;
  opacity: 0;
}
#engineering-page .content .content-description .bullet-section .bullet-content .bullet-list.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#engineering-page .content .content-description p {
  margin: 0px 0px 30px 0px;
  font-size: 1.2vw;
}
#engineering-page .content .content-description .title-with-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2.27vw;
  margin-bottom: 20px;
  font-weight: bold;
}
#engineering-page .content .content-description .title-with-arrow img {
  margin-right: 10px;
  width: 25px;
}
#engineering-page .content .content-description .services-section {
  position: relative;
}
#engineering-page .content .content-description .highlights-section {
  position: relative;
}
#engineering-page .content .content-description .group {
  position: relative;
}
#engineering-page .content .content-description .group .title-group {
  font-size: 2.27vw;
  margin-bottom: 70px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
#engineering-page .content .content-description .group .group-content-desktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
#engineering-page .content .content-description .group .group-content-desktop .highlight-container {
  width: 45%;
  min-height: 200px;
}
#engineering-page .content .content-description .group .group-content-desktop p {
  margin: 0;
}
#engineering-page .content .content-description .group .group-content-mobile {
  display: none;
}
#engineering-page .content .content-description .digital-enablers-content {
  position: relative;
  margin-bottom: 200px;
}

@media (max-width: 499px) {
  #engineering-page {
    top: 0px;
    margin-bottom: 0px;
  }
  #engineering-page .line-wrapper {
    display: none !important;
  }
  #engineering-page .content {
    padding: 90px 20px 0px 20px;
    overflow-x: hidden;
  }
  #engineering-page .content .background {
    display: none;
  }
  #engineering-page .content .background-mobile {
    display: contents;
  }
  #engineering-page .content .background-mobile img {
    left: 50%;
    position: absolute;
    width: 183px;
    height: 168px;
    opacity: 0.21;
  }
  #engineering-page .content .content-description {
    position: relative;
    top: -175px;
  }
  #engineering-page .content .content-description p {
    font-size: 16px;
  }
  #engineering-page .content .content-description .engineering-content {
    margin-bottom: 0;
  }
  #engineering-page .content .content-description .title-content {
    font-size: 19px;
    margin: 20px 0px;
    text-align: left;
  }
  #engineering-page .content .content-description .title-with-arrow {
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  #engineering-page .content .content-description .title-with-arrow img {
    width: 18px;
  }
  #engineering-page .content .content-description .group {
    margin-bottom: 30px;
  }
  #engineering-page .content .content-description .group .title-group {
    font-size: 18px;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  #engineering-page .content .content-description .group .group-content-mobile {
    display: block;
  }
  #engineering-page .content .content-description .group .group-content-desktop {
    display: none;
  }
  #engineering-page .content .content-description .digital-enablers-content {
    margin-bottom: 0px;
  }
  #engineering-page .content .content-description .services-section,
#engineering-page .content .content-description .highlights-section {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #engineering-page {
    top: 50px;
  }
  #engineering-page .line-wrapper {
    display: none;
  }
  #engineering-page .content {
    padding: 20px 50px 0px 50px;
  }
  #engineering-page .content .content-description .title-content {
    font-size: 2.3rem;
    margin: 20px 0;
  }
  #engineering-page .content .content-description .engineering-content > p {
    font-size: 1.5rem;
  }
  #engineering-page .content .content-description .bullet-section .bullet-title {
    font-size: 1.8rem;
  }
  #engineering-page .content .content-description .bullet-section .bullet-content {
    font-size: 1.5rem;
  }
  #engineering-page .content .content-description .bullet-section .bullet-content p {
    font-size: 16px;
  }
  #engineering-page .content .content-description .group .title-group {
    font-size: 1.8rem;
  }
  #engineering-page .content .content-description .group .group-content-desktop .highlight-container {
    margin-bottom: 20px;
  }
  #engineering-page .content .content-description .group .group-content-desktop .highlight-container .title {
    font-size: 16px;
  }
  #engineering-page .content .content-description .group .group-content-desktop .highlight-container .description {
    opacity: 1 !important;
  }
}