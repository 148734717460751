@import '../../../assets/style/style.css';
.cyber-security-page {
  width: 100%;
  overflow: hidden;
  text-align: left;
  color: white;
  margin-bottom: 225px;
  position: relative;
  top: 130px;
}
.cyber-security-page .mouse-container {
  top: 170%;
}
.cyber-security-page .content {
  flex-direction: column;
  padding: 20px 200px 0px 200px;
  position: relative;
}
.cyber-security-page .content .background {
  text-align: center;
}
.cyber-security-page .content .background-mobile {
  display: none;
}
.cyber-security-page .content .content-description .title-content {
  font-weight: bold;
  font-size: 2.92vw;
  margin: 50px 0px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  text-align: center;
  position: relative;
  top: -25px;
  opacity: 0;
}
.cyber-security-page .content .content-description .title-content.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.cyber-security-page .content .content-description .cyber-security-content {
  position: relative;
  margin-bottom: 40px;
  position: relative;
  top: -25px;
  opacity: 0;
}
.cyber-security-page .content .content-description .cyber-security-content.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.cyber-security-page .content .content-description p {
  margin: 0px 0px 30px 0px;
  font-size: 1.2vw;
}
.cyber-security-page .content .content-description .solutions-paragraph .title-solutions {
  font-size: 2.27vw;
  margin-bottom: 25px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
.cyber-security-page .content .content-description .solutions-paragraph .solutions-content > p {
  position: relative;
  top: -25px;
  opacity: 0;
}
.cyber-security-page .content .content-description .solutions-paragraph .solutions-content > p.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.cyber-security-page .content .content-description .solutions-paragraph .mobile-solutions {
  display: none;
}
.cyber-security-page .content .content-description .solutions-paragraph .desktop-solutions {
  display: flex;
  flex-wrap: wrap;
  height: 350px;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -25px;
  opacity: 0;
}
.cyber-security-page .content .content-description .solutions-paragraph .desktop-solutions.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.cyber-security-page .content .content-description .solutions-paragraph .desktop-solutions .solution {
  display: flex;
  height: 30%;
  width: 25%;
}
.cyber-security-page .content .content-description .solutions-paragraph .desktop-solutions .solution img {
  width: 90px;
  height: 90px;
}
.cyber-security-page .content .content-description .solutions-paragraph .desktop-solutions .solution .title {
  height: 110px;
  font-size: 16px;
  padding: 0 20px;
  text-align: left;
  display: flex;
  align-items: center;
}
.cyber-security-page .content .content-description .solutions-paragraph .desktop-solutions .mouse-container {
  top: 100% !important;
}
.cyber-security-page .content .content-description .highlights-paragraph {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cyber-security-page .content .content-description .highlights-paragraph .title-highlights {
  font-size: 2.27vw;
  margin-bottom: 40px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
.cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop {
  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  align-self: center;
}
.cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop p {
  margin: 0;
}
.cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop > :nth-child(1) {
  grid-column: 1/3;
}
.cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop > :nth-child(2) {
  grid-column: 3/5;
}
.cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop > :nth-child(3) {
  grid-column: 5/7;
}
.cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop > :nth-child(4) {
  grid-row: 2;
  grid-column: 2/4;
}
.cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop > :nth-child(5) {
  grid-row: 2;
  grid-column: 4/6;
}
.cyber-security-page .content .content-description .highlights-paragraph .highlights-content-mobile {
  display: none;
}

@media (max-width: 499px) {
  .cyber-security-page {
    top: 45px;
    margin-bottom: 45px;
  }
  .cyber-security-page .line-wrapper {
    display: none !important;
  }
  .cyber-security-page .content {
    padding: 20px 20px 0 20px;
    margin-bottom: 25px;
  }
  .cyber-security-page .content .background {
    display: none;
  }
  .cyber-security-page .content .background-mobile {
    display: contents;
  }
  .cyber-security-page .content .background-mobile img {
    left: 50%;
    position: absolute;
    width: 183px;
    height: 168px;
    opacity: 0.21;
  }
  .cyber-security-page .content .content-description p {
    font-size: 16px;
  }
  .cyber-security-page .content .content-description .title-content {
    font-size: 19px;
    margin: 20px 0px;
    text-align: left;
  }
  .cyber-security-page .content .content-description .cyber-security-content {
    position: relative;
    margin-bottom: 0px;
  }
  .cyber-security-page .content .content-description .cyber-security-content p {
    margin: 0px 0px 30px 0px;
    font-size: 16px;
  }
  .cyber-security-page .content .content-description .solutions-paragraph .title-solutions {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .cyber-security-page .content .content-description .solutions-paragraph .mobile-solutions {
    display: contents;
    height: 100vh;
  }
  .cyber-security-page .content .content-description .solutions-paragraph .desktop-solutions {
    display: none;
  }
  .cyber-security-page .content .content-description .highlights-paragraph {
    margin-top: 60px;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .title-highlights {
    font-size: 18px;
    text-transform: uppercase;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop {
    display: none;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .highlights-content-mobile {
    display: block;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .cyber-security-page {
    top: 50px;
  }
  .cyber-security-page .line-wrapper {
    display: none;
  }
  .cyber-security-page .content {
    padding: 20px 50px 0px 50px;
  }
  .cyber-security-page .content .content-description .title-content {
    font-size: 2.3rem;
  }
  .cyber-security-page .content .content-description .cyber-security-content > p {
    font-size: 1.5rem;
  }
  .cyber-security-page .content .content-description .solutions-paragraph .title-solutions {
    font-size: 1.8rem;
  }
  .cyber-security-page .content .content-description .solutions-paragraph .solutions-content > p {
    font-size: 1.5rem;
  }
  .cyber-security-page .content .content-description .solutions-paragraph .solutions-content .desktop-solutions {
    height: auto;
  }
  .cyber-security-page .content .content-description .solutions-paragraph .solutions-content .desktop-solutions .solution {
    flex-direction: column;
    align-items: center;
    width: 33%;
    margin-bottom: 15px;
  }
  .cyber-security-page .content .content-description .solutions-paragraph .solutions-content .desktop-solutions .solution .title {
    margin: 10px 0;
    height: auto;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .title-highlights {
    font-size: 1.8rem;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop {
    display: none;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop .highlight-container > p {
    font-size: 1rem;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .highlights-content-desktop .highlight-container .description {
    opacity: 1 !important;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .highlights-content-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cyber-security-page .content .content-description .highlights-paragraph .highlights-content-mobile .swiper {
    width: 450px;
    overflow: hidden;
  }
}
/*  SWIPER   */
.swiper {
  width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swiper .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper .swiper-wrapper .swiper-slide {
  width: 100%;
}
.swiper .swiper-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.swiper .swiper-content .title {
  font-weight: bold;
}
.swiper .swiper-content .description {
  margin-bottom: 15px;
}
.swiper .img {
  display: flex;
  height: 100px;
  width: 80px;
  top: 0px;
  left: 0px;
}

.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  position: relative;
  justify-content: space-evenly;
  width: 50% !important;
  margin: 40px 0 0 0;
}
.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  text-align: center;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
.swiper-pagination-bullets .swiper-pagination-bullet-active,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active {
  color: #fff;
  background: #697482;
}