@import '../../assets/style/style.css';
/* HEADER */
.header {
  z-index: 100;
  padding-left: 10px;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  background: #071c2b;
}
.header > a {
  display: flex;
}
.header .logo-header {
  width: 120px;
}

.hover {
  animation-name: headerAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  background: white;
}

.header-mobile {
  display: none;
}

/*  HAMBURGER MENU */
.hamburger-react {
  z-index: 103;
  position: fixed !important;
  right: 2.5px;
  top: -2.5px;
}

/* SIDE MENU */
.side-menu {
  z-index: 102;
  background: #fff;
  height: 100vh;
  width: 17%;
  position: fixed;
  top: 0;
  right: -20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #021e34;
}
.side-menu.opened {
  animation: menuAppearing 0.4s ease-in-out;
  animation-fill-mode: forwards;
}
.side-menu.closed {
  animation: menuDisappearing 0.4s ease-in-out;
  animation-fill-mode: forwards;
}
.side-menu .mobile-hamburger-container {
  display: none;
}
.side-menu .menu-list {
  width: 100%;
  height: 80%;
  margin: 80px 0 0 0;
  padding-left: 40px;
  text-align: left;
  list-style: none;
}
.side-menu .menu-list .list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.side-menu .menu-list .list-item > a {
  margin-top: 20px;
  font-size: 1vw;
  text-decoration: none;
  color: #021e34;
}
.side-menu .menu-list .list-item > a:hover {
  font-size: 1.05vw;
  font-weight: bold;
}
.side-menu .menu-list .list-item .menu-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.side-menu .menu-list .list-item .menu-button > img {
  margin-left: 5px;
  width: 80%;
}
.side-menu .menu-list .submenu-container {
  margin-top: 20px;
}
.side-menu .menu-list .submenu-container .label-container > label {
  display: flex;
  align-items: center;
}
.side-menu .menu-list .submenu-container .label-container > label > img {
  margin-left: 5px;
}
.side-menu .menu-list .submenu-container .label-container > label > img:hover {
  cursor: pointer;
}
.side-menu .menu-list .submenu-container .label-container > label > span {
  color: #021e34;
  font-size: 1vw;
  font-variant: small-caps;
  cursor: pointer;
  display: block;
}
.side-menu .menu-list .submenu-container .label-container > label > span:hover {
  font-size: 1.05vw;
  font-weight: bold;
}
.side-menu .menu-list .submenu-container .label-container > label > span::after {
  float: right;
}
.side-menu .menu-list .slide {
  clear: both;
  width: 100%;
  height: 0px;
  overflow: hidden;
  text-align: center;
  transition: height 0.4s ease;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.side-menu .menu-list .slide > li {
  list-style: none;
}
.side-menu .menu-list .slide > li > a {
  font-size: 0.85vw;
  text-decoration: none;
  color: #021e34;
}
.side-menu .menu-list .slide > li > a > img {
  margin-right: 3px;
}
.side-menu .menu-list .slide > li > a:hover {
  font-size: 0.9vw;
  font-weight: bold;
}
.side-menu .menu-list #touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}
.side-menu .menu-list #touch:checked + .slide {
  height: 150px;
}
.side-menu .icons-menu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 40px;
}
.side-menu .icons-menu a img {
  width: 22px;
  margin-right: 20px;
}
.side-menu .icons-menu a img:hover {
  opacity: 0.7;
  cursor: pointer;
}
.side-menu .menu-copyright {
  padding: 10px 0;
  margin-left: 40px;
  text-align: left;
  opacity: 0.4;
}

@keyframes headerAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menuAppearing {
  0% {
    right: -20%;
  }
  100% {
    right: 0;
  }
}
@keyframes menuDisappearing {
  0% {
    right: 0%;
  }
  100% {
    right: -20%;
  }
}
@media (max-width: 499px) {
  .header {
    display: none;
  }
  .header-mobile {
    z-index: 100;
    padding-left: 10px;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    background: #fff;
  }
  .header-mobile > a {
    display: flex;
  }
  .header-mobile .logo-header {
    width: 90px;
  }
  .side-menu.opened {
    position: fixed;
    width: 100% !important;
    height: 100vh;
    overflow: hidden !important;
  }
  .side-menu.opened {
    animation: menuAppearing 0s ease-in-out;
    animation-fill-mode: forwards;
  }
  .side-menu.closed {
    animation: menuDisappearing 0s ease-in-out;
    animation-fill-mode: forwards;
  }
  .side-menu .mobile-hamburger-container {
    display: contents;
  }
  .side-menu .desktop-hamburger-container {
    display: none;
  }
  .side-menu .menu-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0px;
  }
  .side-menu .menu-list .list-item > a {
    font-size: 22px;
  }
  .side-menu .menu-list .list-item > a:hover {
    font-size: 22px;
    font-weight: bold;
  }
  .side-menu .menu-list .submenu-container .label-container > label > span {
    font-size: 22px;
  }
  .side-menu .menu-list .submenu-container .label-container > label > span:hover {
    font-size: 22px;
    font-weight: bold;
  }
  .side-menu .menu-list .slide > li > a {
    font-size: 18px;
    text-transform: uppercase;
    font-style: oblique;
  }
  .side-menu .menu-list .slide > li > a:hover {
    font-size: 18px;
  }
  .side-menu .menu-list .slide > li > a img {
    margin-right: 8px;
  }
  .side-menu .menu-list #touch:checked + .slide {
    height: 200px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .header {
    display: none;
  }
  .header-mobile {
    z-index: 100;
    padding-left: 10px;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    background: #fff;
  }
  .header-mobile > a {
    display: flex;
  }
  .header-mobile .logo-header {
    width: 90px;
  }
  .side-menu.opened {
    position: fixed;
    width: 100% !important;
    height: 100vh;
    overflow: hidden !important;
  }
  .side-menu.opened {
    animation: menuAppearing 0s ease-in-out;
    animation-fill-mode: forwards;
  }
  .side-menu.closed {
    animation: menuDisappearing 0s ease-in-out;
    animation-fill-mode: forwards;
  }
  .side-menu .mobile-hamburger-container {
    display: contents;
  }
  .side-menu .desktop-hamburger-container {
    display: none;
  }
  .side-menu .menu-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0px;
  }
  .side-menu .menu-list .list-item > .menu-button > img {
    width: 64px;
    margin-left: 16px;
  }
  .side-menu .menu-list .list-item > a {
    font-size: 28px;
  }
  .side-menu .menu-list .list-item > a:hover {
    font-size: 28px;
    font-weight: bold;
  }
  .side-menu .menu-list .submenu-container .label-container > label > img {
    width: 32px;
  }
  .side-menu .menu-list .submenu-container .label-container > label > span {
    font-size: 28px;
  }
  .side-menu .menu-list .submenu-container .label-container > label > span:hover {
    font-size: 32px;
    font-weight: bold;
  }
  .side-menu .menu-list .slide > li > a {
    font-size: 22px;
    text-transform: uppercase;
    font-style: oblique;
  }
  .side-menu .menu-list .slide > li > a:hover {
    font-size: 22px;
  }
  .side-menu .menu-list .slide > li > a img {
    width: 16px;
    margin-right: 8px;
  }
  .side-menu .menu-list #touch:checked + .slide {
    height: 200px;
  }
  .side-menu .icons-menu > a > img {
    width: 32px;
  }
}