@import '../../../assets/style/style.css';
.cyberSecurity-container {
  align-self: center;
  align-items: center;
  display: flex;
  height: 100vh;
  width: 75%;
  justify-content: space-evenly;
  margin-left: 10%;
}
.cyberSecurity-container .cyberSecurity-image {
  z-index: 10;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  /*.shield-lines {
    stroke-dasharray: 10;
    stroke-dashoffset: 10;
    animation: linesAnimation 1s ease-in-out infinite;
  }
   */
}
.cyberSecurity-container .cyberSecurity-image hr {
  display: none;
}
.cyberSecurity-container .cyberSecurity-image > img {
  width: 70%;
}
.cyberSecurity-container .cyberSecurity-text__container {
  display: flex;
  flex-direction: column;
  height: 80%;
  text-align: right;
  justify-content: center;
  align-items: flex-end;
  width: 60%;
}
.cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.1vw;
  position: relative;
  top: 40px;
  opacity: 0;
}
.cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text h1 {
  font-size: 2.92vw;
  letter-spacing: 0.2em;
}
.cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text .cyberSecurity-text__description {
  font-weight: 400;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.2vw;
}
.cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-page-button {
  display: flex;
  width: 60%;
  justify-content: flex-end;
  position: relative;
  top: 40px;
  opacity: 0;
}
.cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-page-button.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}

@media (max-width: 499px) {
  .cyberSecurity-container {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .cyberSecurity-container .cyberSecurity-text__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    width: 80%;
    height: 100%;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 1.1vw;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text .cyberSecurity-text__title h1 {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text .cyberSecurity-text__description {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text .cyberSecurity-text__description p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-page-button {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 50px;
  }
  .cyberSecurity-container .cyberSecurity-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .cyberSecurity-container .cyberSecurity-image svg {
    width: 180px;
    height: 250px;
  }
  .cyberSecurity-container .cyberSecurity-image hr {
    display: block;
    color: white;
    margin-top: -4px;
    position: absolute;
    width: 100%;
    z-index: -1;
    left: 2px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .cyberSecurity-container {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 100%;
    height: 100vh;
    margin-left: 0;
  }
  .cyberSecurity-container .cyberSecurity-text__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    width: 80%;
    height: 45%;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 1.1vw;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text .cyberSecurity-text__title {
    text-align: center;
    width: 100%;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text .cyberSecurity-text__title h1 {
    font-size: 2.3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text .cyberSecurity-text__description {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-text .cyberSecurity-text__description p {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .cyberSecurity-container .cyberSecurity-text__container .cyberSecurity-page-button {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 50px;
  }
  .cyberSecurity-container .cyberSecurity-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    height: 35%;
  }
  .cyberSecurity-container .cyberSecurity-image svg {
    margin-top: 50px;
  }
  .cyberSecurity-container .cyberSecurity-image hr {
    display: none;
  }
}