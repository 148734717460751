@import '../../assets/style/style.css';
.kbart-container {
  color: white;
  width: 100%;
}
@media (min-width: 1024px) {
  .kbart-container {
    overflow: hidden;
    text-align: left;
    color: white;
    margin-bottom: 225px;
    position: relative;
    top: 140px;
  }
}
.kbart-container .scrolling-line {
  display: none;
}
@media (min-width: 1024px) {
  .kbart-container .scrolling-line {
    display: flex;
  }
}
.kbart-container .kbart-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
.kbart-container .kbart-content .kbart-text-wrapper {
  display: none;
  position: relative;
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-text-wrapper {
    text-align: left;
    width: 23%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
  }
  .kbart-container .kbart-content .kbart-text-wrapper img {
    opacity: 0.95;
    width: 200px;
  }
  .kbart-container .kbart-content .kbart-text-wrapper > p {
    text-align: center;
    font-size: 2.92vw;
    font-family: TitilliumWeb-ExtraLight;
    line-height: 68px;
    letter-spacing: 0.2em;
  }
  .kbart-container .kbart-content .kbart-text-wrapper .kbart-subtitle {
    font-family: TitilliumWeb-ExtraLight;
    font-size: 2.3rem;
  }
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > img {
    position: absolute;
    width: 16px;
    left: 35%;
    -webkit-transition: left 0.4s ease-in-out;
    transition: left 0.4s ease-in-out;
    opacity: 0.5;
  }
}
@media (min-width: 1024px) and (min-width: 768px) and (max-width: 900px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > img {
    left: 23%;
  }
}
@media (min-width: 1024px) and (min-width: 900px) and (max-width: 1100px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > img {
    left: 30%;
  }
}
@media (min-width: 1024px) and (min-width: 1100px) and (max-width: 1400px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > img {
    left: 32%;
  }
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > input {
    height: 45px;
    width: 90%;
    border-radius: 10px;
    padding: 0px 30px;
    font-size: 15px;
    text-indent: 38%;
    transition: text-indent 0.4s ease-in-out;
  }
}
@media (min-width: 1024px) and (min-width: 1120px) and (max-width: 1400px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > input {
    text-indent: 34%;
  }
}
@media (min-width: 1024px) and (min-width: 1000px) and (max-width: 1120px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > input {
    text-indent: 32%;
  }
}
@media (min-width: 1024px) and (min-width: 900px) and (max-width: 1000px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > input {
    text-indent: 28%;
  }
}
@media (min-width: 1024px) and (min-width: 768px) and (max-width: 900px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper > input {
    text-indent: 24%;
  }
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper .input-clicked {
    text-indent: 3px !important;
  }
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-text-wrapper .input-wrapper .input-icon-clicked {
    left: 8%;
  }
}
.kbart-container .kbart-content .kbart-header-mini {
  align-items: center;
  position: absolute;
  top: 45px;
  background-color: #021e34;
  border-bottom: 2px solid white;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  z-index: 2;
  padding: 14px 10px;
}
.kbart-container .kbart-content .kbart-header-mini h1 {
  font-weight: bold;
  display: flex;
  text-indent: 15px;
  font-size: 19px;
  width: 70%;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .kbart-container .kbart-content .kbart-header-mini h1 {
    font-size: 2.3rem;
  }
}
.kbart-container .kbart-content .kbart-header-mini .input-wrapper-mobile {
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: flex-end;
  cursor: pointer;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .kbart-container .kbart-content .kbart-header-mini .input-wrapper-mobile {
    width: 180px;
  }
}
.kbart-container .kbart-content .kbart-header-mini .input-wrapper-mobile #input-search-mobile {
  width: 65%;
  border-color: transparent;
  background-color: transparent;
  outline: none;
  color: white;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .kbart-container .kbart-content .kbart-header-mini .input-wrapper-mobile #input-search-mobile {
    width: 100%;
    height: 50px;
    font-size: 20px;
  }
}
.kbart-container .kbart-content .kbart-header-mini .input-wrapper-mobile #input-search-mobile::placeholder {
  color: white;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .kbart-container .kbart-content .kbart-header-mini .input-wrapper-mobile #input-search-mobile::placeholder {
    font-size: 1.5rem;
  }
}
.kbart-container .kbart-content .kbart-header-mini .input-wrapper-mobile .search-icon {
  width: 15px;
  height: 15px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .kbart-container .kbart-content .kbart-header-mini .input-wrapper-mobile .search-icon {
    width: 24px;
    height: 24px;
  }
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-header-mini {
    display: none;
  }
}
.kbart-container .kbart-content .kbart-logos-container {
  position: relative;
  padding: 20px 0;
  width: 100%;
  height: calc(100% - 85px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-logos-container {
    overflow-y: auto;
    width: 60%;
  }
}
.kbart-container .kbart-content .kbart-logos-container::-webkit-scrollbar {
  width: 3px;
}
.kbart-container .kbart-content .kbart-logos-container::-webkit-scrollbar-track {
  background-color: none;
}
.kbart-container .kbart-content .kbart-logos-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 5px;
}
.kbart-container .kbart-content .kbart-logos-container .kbart-full {
  display: none;
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-logos-container .kbart-full {
    display: block;
  }
}
.kbart-container .kbart-content .kbart-logos-container .kbart-mini {
  display: flex;
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-logos-container .kbart-mini {
    display: none;
  }
}
.kbart-container .kbart-content .kbart-logos-container .logo-details {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 45%;
  height: 65%;
  position: relative;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details.kbart-full {
  display: none;
}
@media (min-width: 1024px) {
  .kbart-container .kbart-content .kbart-logos-container .logo-details.kbart-full {
    display: block;
  }
}
.kbart-container .kbart-content .kbart-logos-container .logo-details .image-container {
  border-radius: 5px;
  background: white;
  position: relative;
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details .image-container .logo-name {
  opacity: 0;
  background-color: #021e34;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.3vw;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details .image-container .logo-name:hover {
  opacity: 0.9;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details .image-container > img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details > a {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  margin-left: 30px;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details > p {
  font-size: 1.375vw;
  margin: 10px 0;
  text-align: left;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details .linkedin-icon {
  opacity: 1;
  position: absolute;
  left: 0;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details .linkedin-icon:hover {
  opacity: 0.7;
}
.kbart-container .kbart-content .kbart-logos-container .logo-details .linkedin-none {
  opacity: 0.3;
  position: absolute;
  left: 0;
}
.kbart-container .kbart-content .kbart-logos-container .empty-record {
  font-size: 30px;
  font-style: italic;
  color: #9ba5ae;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 30%;
}
.kbart-container .mobile-search {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.kbart-container .mobile-search .mobile-search-cards {
  display: flex;
  align-items: center;
  width: 100%;
}
.kbart-container .mobile-search .mobile-search-cards .cards {
  align-items: center;
  color: #949494;
  display: flex;
  flex-direction: row;
  height: 500px;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}
.kbart-container .mobile-search .mobile-search-cards .cards li {
  display: flex;
  margin: 0 15px;
  scroll-snap-align: center;
}
.kbart-container .mobile-search .dots {
  display: flex;
  position: relative;
  align-self: center;
  justify-content: space-evenly;
  width: 95%;
}
.kbart-container .mobile-search .dots .dot {
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  background-color: white;
  cursor: pointer;
}
@media (max-width: 1600px) {
  .kbart-container .input-icon-clicked {
    left: 9% !important;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .kbart-container .input-icon-clicked {
    left: 10% !important;
  }
}
.kbart-container .footer-container {
  display: none;
}
@media (min-width: 1024px) {
  .kbart-container .footer-container {
    display: block;
  }
}