.highlight-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 250px;
    position: relative;
    top: 40px;
    opacity: 0;
  }
  .highlight-container.animate {
    animation: fadeIn 0.6s ease-in-out forwards;
  }
  .highlight-container img {
    width: 86px;
    height: 68px;
    margin-bottom: 10px;
    transition: width 300ms, height 300ms;
    transition-timing-function: ease-out;
  }
  .highlight-container .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .highlight-container .description {
    font-size: 16px !important;
    text-align: center;
    opacity: 0;
    transition: opacity 300ms;
    transition-timing-function: ease-out;
  }