@import '../../assets/style/style.css';
.about-us {
  color: white;
  width: 100%;
  height: 400vh;
  overflow: hidden;
}
.about-us #paolo-penna,
.about-us #maurizio-sacco,
.about-us #michele-colella,
.about-us #logo2007,
.about-us #logo2009,
.about-us #logo2015,
.about-us #logo2019,
.about-us #logo2021,
.about-us #logo2007-active,
.about-us #logo2009-active,
.about-us #logo2015-active,
.about-us #logo2019-active,
.about-us #logo2021-active,
.about-us #foundation-active,
.about-us #innovation-active,
.about-us #cyber-active,
.about-us #employers-active,
.about-us #engineering-active,
.about-us #metamorphosis-active,
.about-us #byz-active {
  z-index: 10;
  visibility: hidden;
}
.about-us #arrow {
  z-index: 5;
  visibility: hidden;
}
.about-us .paolo-text,
.about-us .maurizio-text,
.about-us .michele-text {
  visibility: hidden;
  font-size: 3.5px;
}
.about-us .logo-evolution-title,
.about-us .timeline-title {
  font-size: 7.5px;
  font-weight: bolder;
}
.about-us .logo-evolution-description,
.about-us .timeline-description {
  font-size: 4px;
}
.about-us .logo2007-text,
.about-us .logo2009-text,
.about-us .logo2015-text,
.about-us .logo2019-text,
.about-us .logo2021-text,
.about-us .foundation-text,
.about-us .innovation-text,
.about-us .cyber-text,
.about-us .employers-text,
.about-us .engineering-text,
.about-us .metamorphosis-text,
.about-us .byz-text {
  visibility: hidden;
  font-size: 3px;
}
.about-us .innovation-text,
.about-us #innovation-factory,
.about-us .cyber-text,
.about-us #cyber-security,
.about-us .engineering-text,
.about-us #engineering,
.about-us .byz-text,
.about-us #byz {
  cursor: pointer;
}
.about-us .about-us__section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.about-us .about-us__section .aboutus-line {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
}
.about-us .about-us__section .section-content {
  position: relative;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 100px;
}
.about-us .about-us__section .section-content > img {
  height: 200px;
  margin-right: 80px;
  margin-bottom: 80px;
}
.about-us .about-us__section .section-content .section-text-container {
  justify-content: space-evenly;
  flex-direction: column;
  display: flex;
  height: 55%;
  position: relative;
  top: 40px;
  opacity: 0;
}
.about-us .about-us__section .section-content .section-text-container.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.about-us .about-us__section .section-content .section-text-container .title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 2.92vw;
  font-weight: bold;
  font-family: TitilliumWeb-Light;
}
.about-us .about-us__section .section-content .section-text-container .description-container {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.about-us .about-us__section .section-content .section-text-container .description-container #first-paragraph {
  margin: 0px 0px !important;
  font-family: TitilliumWeb-Light;
}
.about-us .about-us__section .section-content .section-text-container .description-container > p {
  font-family: TitilliumWeb-Light;
  margin: 30px 0;
  font-size: 1.375vw;
  text-align: left;
}
.about-us .about-us__section .section-content .section-text-container .description-container .main-p {
  font-family: TitilliumWeb-Light;
  font-size: 1.375vw;
}
.about-us .about-us__section .section-content .section-text-container > p {
  margin: 30px 0;
  font-size: 1.375vw;
  text-align: left;
}
.about-us .about-us__section .section-content .section-text-container .main-p {
  font-size: 1.375vw;
}
.about-us .about-us__section .section-content .section-text-container .byz-container {
  width: 20%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.about-us .about-us__section .section-content .section-text-container .byz-container > img {
  transform: rotateY(180deg);
  width: 15vw;
}
.about-us .timeline-section {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: calc(100vh - 42px);
}
.about-us .timeline-section .section-content {
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -25px;
  opacity: 0;
}
.about-us .timeline-section .section-content.animate {
  animation: fadeAboutUs 0.6s ease-in-out forwards;
}
.about-us .timeline-section .section-content > p {
  text-align: left;
  font-size: 1.375vw;
}
.about-us .third-section,
.about-us .fourth-section {
  height: 100vh;
  width: 100%;
}

.about-us-mobile {
  display: none;
}

@media (max-width: 499px) {
  .about-us {
    display: none;
  }
  .about-us-mobile {
    height: 100%;
    margin-bottom: 150px;
    display: block;
    justify-content: center;
    align-items: center;
  }
  .about-us-mobile .content-container {
    position: relative;
    top: 45px;
    padding: 15px 30px;
    border-bottom: 3px solid white;
    margin-bottom: 60px;
  }
  .about-us-mobile .content-container .title-content {
    text-align: left;
  }
  .about-us-mobile .content-container .title-content h1 {
    color: white;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: bold;
  }
  .about-us-mobile .description-container {
    text-align: left;
    color: white;
    padding: 0px 30px;
  }
  .about-us-mobile .description-container p {
    margin: 30px 0;
  }
  .about-us-mobile .icon-founders-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0px 30px;
    margin: 30px 0px 72px 0px;
    color: white;
  }
  .about-us-mobile .icon-founders-container .PP,
.about-us-mobile .icon-founders-container .MS,
.about-us-mobile .icon-founders-container .MP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-us-mobile .icon-founders-container .PP .role,
.about-us-mobile .icon-founders-container .MS .role,
.about-us-mobile .icon-founders-container .MP .role {
    font-family: "TitilliumWeb-ExtraLight";
  }
  .about-us-mobile .icon-founders-container .PP img,
.about-us-mobile .icon-founders-container .MS img,
.about-us-mobile .icon-founders-container .MP img {
    width: 15px;
    height: 15px;
  }
  .about-us-mobile .icon-founders-container .founder-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
  }
  .about-us-mobile .icon-founders-container .founder-circle-container .founder-icon {
    display: flex;
    width: 90px;
    height: 90px;
  }
  .about-us-mobile .icon-founders-container .founder-circle-container .founder-circle {
    display: flex;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: transparent;
    box-shadow: 0px 0px 0px 2.5px white;
    margin-top: 11px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translateZ(-25px);
    transform: translateZ(-25px);
  }
  .about-us-mobile .icon-founders-container .founder-circle-container div {
    margin: 12px 0px;
  }
  .about-us-mobile .icon-founders-container img {
    width: 120px;
  }
  .about-us-mobile .icon-founders-container .bold {
    font-weight: bold;
  }
  .about-us-mobile .timeline-container {
    margin-bottom: 150px;
  }
  .about-us-mobile .timeline-container .timeline-title {
    position: relative;
    margin-bottom: 80px;
    color: white;
    text-align: center;
  }
  .about-us-mobile .timeline-container .timeline-title :first-child {
    font-size: 28px;
    font-weight: bold;
  }
  .about-us-mobile .timeline-container .timeline-title hr {
    width: 100%;
    color: white;
    margin: 0;
  }
  .about-us-mobile .timeline-container .timeline-content {
    display: flex;
    position: relative;
    margin-bottom: 96px;
    justify-content: center;
    margin-left: 1px;
  }
  .about-us-mobile .timeline-container .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 2px;
    margin-top: 5px;
  }
  .about-us-mobile .timeline-container .line #line-logo {
    height: 400px;
  }
  .about-us-mobile .timeline-container .line .circle-container {
    position: absolute;
    display: flex;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(2) {
    top: 0px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(3) {
    top: 100px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(4) {
    top: 200px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(5) {
    top: 300px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(6) {
    top: 400px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(7) {
    top: 500px;
  }
  .about-us-mobile .timeline-container .line .circle-container .circle {
    display: flex;
    background-color: white;
    width: 10px;
    height: 10px;
    border-radius: 100px;
  }
  .about-us-mobile .timeline-container .steps .step {
    display: flex;
    flex-direction: column;
    color: white;
    width: 165px;
    position: absolute;
    font-size: 14px;
  }
  .about-us-mobile .timeline-container .steps #first {
    text-align: left;
    top: -5px;
    right: 0;
  }
  .about-us-mobile .timeline-container .steps #second {
    text-align: right;
    top: 95px;
    left: 0;
  }
  .about-us-mobile .timeline-container .steps #third {
    text-align: left;
    top: 195px;
    right: 0;
  }
  .about-us-mobile .timeline-container .steps #fourth {
    text-align: right;
    top: 295px;
    left: 0;
  }
  .about-us-mobile .timeline-container .steps #fifth {
    text-align: left;
    top: 395px;
    right: 0;
  }
  .about-us-mobile .timeline-container .steps #sixth {
    text-align: right;
    top: 495px;
    left: 0;
  }
  .about-us-mobile .timeline-container .steps .logo-step {
    align-items: center;
  }
  .about-us-mobile .timeline-container .steps .logo-step img {
    width: 120px;
  }
  .about-us-mobile .timeline-container .steps .logo-step span {
    text-align: center;
  }
  .about-us-mobile .timeline-container .steps #first-logo {
    text-align: left;
    top: -25px;
    left: 30px;
  }
  .about-us-mobile .timeline-container .steps #second-logo {
    text-align: right;
    top: 75px;
    right: 30px;
  }
  .about-us-mobile .timeline-container .steps #third-logo {
    text-align: left;
    top: 175px;
    left: 30px;
  }
  .about-us-mobile .timeline-container .steps #fourth-logo {
    text-align: right;
    top: 275px;
    right: 30px;
  }
  .about-us-mobile .timeline-container .steps #fifth-logo {
    text-align: left;
    top: 375px;
    left: 30px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .about-us {
    display: none;
  }
  .about-us-mobile {
    height: 100%;
    margin-bottom: 150px;
    display: block;
    justify-content: center;
    align-items: center;
  }
  .about-us-mobile .content-container {
    position: relative;
    top: 45px;
    padding: 15px 30px;
    border-bottom: 3px solid white;
    margin-bottom: 60px;
  }
  .about-us-mobile .content-container .title-content {
    text-align: left;
  }
  .about-us-mobile .content-container .title-content h1 {
    color: white;
    text-transform: uppercase;
    font-size: 2.3rem;
    font-weight: bold;
  }
  .about-us-mobile .description-container {
    text-align: left;
    color: white;
    padding: 0px 30px;
  }
  .about-us-mobile .description-container p {
    font-size: 1.5rem;
    margin: 30px 0;
  }
  .about-us-mobile .icon-founders-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0px 30px;
    margin: 30px 0px 72px 0px;
    color: white;
  }
  .about-us-mobile .icon-founders-container .PP,
.about-us-mobile .icon-founders-container .MS,
.about-us-mobile .icon-founders-container .MP {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
  .about-us-mobile .icon-founders-container .PP .role,
.about-us-mobile .icon-founders-container .MS .role,
.about-us-mobile .icon-founders-container .MP .role {
    font-family: "TitilliumWeb-ExtraLight";
  }
  .about-us-mobile .icon-founders-container .PP a > img,
.about-us-mobile .icon-founders-container .MS a > img,
.about-us-mobile .icon-founders-container .MP a > img {
    margin-top: 12px;
  }
  .about-us-mobile .icon-founders-container .PP img,
.about-us-mobile .icon-founders-container .MS img,
.about-us-mobile .icon-founders-container .MP img {
    width: 32px;
    height: 32px;
  }
  .about-us-mobile .icon-founders-container .founder-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
  }
  .about-us-mobile .icon-founders-container .founder-circle-container .founder-icon {
    display: flex;
    width: 90px;
    height: 90px;
  }
  .about-us-mobile .icon-founders-container .founder-circle-container .founder-circle {
    display: flex;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: transparent;
    box-shadow: 0px 0px 0px 2.5px white;
    margin-top: 11px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translateZ(-25px);
    transform: translateZ(-25px);
  }
  .about-us-mobile .icon-founders-container .founder-circle-container div {
    margin: 12px 0px;
  }
  .about-us-mobile .icon-founders-container img {
    width: 120px;
  }
  .about-us-mobile .icon-founders-container .bold {
    font-weight: bold;
  }
  .about-us-mobile .timeline-container {
    margin-bottom: 150px;
  }
  .about-us-mobile .timeline-container .timeline-title {
    position: relative;
    margin-bottom: 80px;
    color: white;
    text-align: center;
  }
  .about-us-mobile .timeline-container .timeline-title :first-child {
    font-size: 36px;
    font-weight: bold;
  }
  .about-us-mobile .timeline-container .timeline-title > :nth-child(3) {
    font-size: 24px;
  }
  .about-us-mobile .timeline-container .timeline-title hr {
    width: 100%;
    color: white;
    margin: 0;
  }
  .about-us-mobile .timeline-container .timeline-content {
    display: flex;
    position: relative;
    margin-bottom: 96px;
    justify-content: center;
    margin-left: 1px;
  }
  .about-us-mobile .timeline-container .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 2px;
    margin-top: 5px;
  }
  .about-us-mobile .timeline-container .line #line-logo {
    height: 400px;
  }
  .about-us-mobile .timeline-container .line .circle-container {
    position: absolute;
    display: flex;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(2) {
    top: 0px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(3) {
    top: 100px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(4) {
    top: 200px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(5) {
    top: 300px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(6) {
    top: 400px;
  }
  .about-us-mobile .timeline-container .line .circle-container:nth-child(7) {
    top: 500px;
  }
  .about-us-mobile .timeline-container .line .circle-container .circle {
    display: flex;
    background-color: white;
    width: 10px;
    height: 10px;
    border-radius: 100px;
  }
  .about-us-mobile .timeline-container .steps .step {
    display: flex;
    flex-direction: column;
    color: white;
    width: 165px;
    position: absolute;
    font-size: 16px;
  }
  .about-us-mobile .timeline-container .steps #first {
    text-align: left;
    top: -25px;
    right: -40%;
    left: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #second {
    text-align: right;
    top: 75px;
    left: -40%;
    right: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #third {
    text-align: left;
    top: 175px;
    right: -40%;
    left: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #fourth {
    text-align: right;
    top: 275px;
    left: -40%;
    right: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #fifth {
    text-align: left;
    top: 375px;
    right: -40%;
    left: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #sixth {
    text-align: right;
    top: 475px;
    left: -40%;
    right: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps .logo-step {
    align-items: center;
  }
  .about-us-mobile .timeline-container .steps .logo-step img {
    width: 120px;
  }
  .about-us-mobile .timeline-container .steps .logo-step span {
    text-align: center;
  }
  .about-us-mobile .timeline-container .steps #first-logo {
    text-align: left;
    top: -25px;
    left: -30%;
    right: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #second-logo {
    text-align: right;
    top: 75px;
    right: -30%;
    left: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #third-logo {
    text-align: left;
    top: 175px;
    left: -30%;
    right: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #fourth-logo {
    text-align: right;
    top: 275px;
    right: -30%;
    left: 0;
    margin: 0 auto;
  }
  .about-us-mobile .timeline-container .steps #fifth-logo {
    text-align: left;
    top: 375px;
    left: -30%;
    right: 0;
    margin: 0 auto;
  }
}