@import '../../assets/style/style.css';
.byz-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  width: 100%;
  justify-content: center;
}
.byz-page .desktop {
  display: none;
}
.byz-page .mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  position: relative;
  text-align: start;
  width: 90%;
}
@media (min-width: 1024px) {
  .byz-page .desktop {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    width: 100%;
    position: relative;
    margin-top: 140px;
  }
  .byz-page .mobile {
    display: none;
  }
}

.content-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.content-wrapper .idea-section {
  position: relative;
  width: 100%;
}
.content-wrapper .idea-section .idea-content {
  position: relative;
  font-size: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding: 20px 200px 0px 200px;
}
.content-wrapper .idea-section .idea-content .logo {
  text-align: center;
  width: 100%;
}
.content-wrapper .idea-section .idea-content > h1 {
  margin: 15px 0;
  letter-spacing: 0.2em;
  font-size: 2.92vw;
  text-align: center;
  width: 100%;
  position: relative;
  top: -25px;
  opacity: 0;
}
.content-wrapper .idea-section .idea-content > h1.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.content-wrapper .idea-section .idea-content > p {
  font-size: 1.2vw;
  margin: 0px 0px 30px 0px;
  position: relative;
  top: -25px;
  opacity: 0;
}
.content-wrapper .idea-section .idea-content > p.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.content-wrapper .book-section {
  padding-top: 5vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  width: 100%;
}
.content-wrapper .book-section.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.content-wrapper .book-section figure {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  height: 65vh;
  width: 75%;
}
.content-wrapper .book-section h1 {
  font-size: 2.27vw;
  letter-spacing: 0.1em;
}
.content-wrapper .third-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: space-evenly;
  margin-bottom: 65px;
  position: relative;
  width: 100%;
}
.content-wrapper .third-section h1 {
  font-size: 2.27vw;
  letter-spacing: 0.1em;
}
.content-wrapper .third-section .sketches-container {
  width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 499px) {
  .byz-page {
    width: 100%;
    overflow-x: hidden;
    text-align: left;
    color: white;
    margin-bottom: 225px;
    position: relative;
    top: 0px;
    display: block;
  }
  .byz-page .line-wrapper {
    display: none;
  }
  #mobile-content {
    width: 100%;
  }
  #mobile-content .title {
    font-size: 19px;
    font-weight: bold;
    position: relative;
    top: 45px;
    padding: 15px 20px;
    border-bottom: 3px solid white;
    margin-bottom: 60px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
  #mobile-content .byz-idea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 90%;
  }
  #mobile-content .byz-idea h3 {
    margin: 15px 0;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
  #mobile-content .byz-idea p {
    font-size: 16px;
  }
  section {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-top: 10px;
    width: 100%;
  }
  .byz-book {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .byz-book h3 {
    margin: 15px 0;
    align-self: flex-start;
  }
  .byz-book figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .byz-book figure svg {
    display: flex;
    height: 30vh;
    width: 90vh;
  }
  .byz-sketches figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .byz-sketches figure .sketches {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .byz-sketches figure svg {
    display: flex;
    height: 180px;
    width: 150px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .byz-page {
    position: relative;
    top: 50px;
  }
  .byz-page .line-wrapper {
    display: none;
  }
  .byz-page .desktop {
    display: block;
  }
  .byz-page .desktop .idea-content {
    padding: 50px 50px 0 50px;
  }
  .byz-page .desktop .idea-content > h1 {
    font-size: 2.3rem;
  }
  .byz-page .desktop .idea-content > p {
    font-size: 1.5rem;
  }
  .byz-page .desktop .book-section > h1 {
    font-size: 1.8rem;
  }
  .byz-page .desktop .book-section figure {
    height: 500px;
    width: 90%;
  }
  .byz-page .desktop .book-section figure > svg {
    width: 100%;
  }
  .byz-page .desktop .third-section {
    margin: 50px 0;
    height: auto;
    justify-content: flex-start;
  }
  .byz-page .desktop .third-section > h1 {
    font-size: 1.8rem;
  }
  .byz-page .desktop .third-section .sketches-container {
    justify-content: space-evenly;
    margin: 100px 0;
    height: 40%;
  }
  .byz-page .desktop .third-section .sketches-container > svg {
    max-width: 150px;
    max-height: 250px;
  }
  .byz-page .mobile {
    display: none;
    margin-bottom: 100px;
  }
  .byz-page .mobile .title {
    font-size: 2.3rem;
  }
  .byz-page .mobile .byz-idea section {
    font-size: 1.5rem;
  }
  .byz-page .mobile .byz-idea section > h3 {
    margin: 20px 0;
    font-size: 1.8rem;
  }
  .byz-page .mobile .byz-idea .byz-book {
    margin: 50px 0;
  }
  .byz-page .mobile .byz-idea .byz-book > figure {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  .byz-page .mobile .byz-idea .byz-sketches > figure .sketches {
    display: flex;
    align-items: center;
  }
  .byz-page .mobile .byz-idea .byz-sketches > figure .sketches > svg {
    max-height: 200px;
    width: 150px;
  }
}