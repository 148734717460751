.bitten-cookie {
  opacity: 0;
}
.bitten-cookie.animate {
  animation: cookieFadeIn 0.8s ease-in-out forwards 0.4s;
}

.whole-cookie {
  opacity: 1;
}
.whole-cookie.animate {
  animation: cookieFadeOut 0.7s ease-in-out forwards 1s;
}

@keyframes cookieFadeIn {
  0% {
    opacity: 0;
  }
  47% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes cookieFadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}