@import '../../../assets/style/style.css';
#digital-factory {
  position: relative;
  top: 130px;
  width: 100%;
}
@media (max-width: 499px) {
  #digital-factory {
    top: 0;
  }
  #digital-factory .line-wrapper {
    display: none !important;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory {
    top: 80px;
  }
  #digital-factory .line-wrapper {
    display: none !important;
  }
}
#digital-factory .content {
  margin-bottom: 120px;
  flex-direction: column;
  padding: 20px 200px 0px 200px;
  position: relative;
  overflow-y: hidden;
}
@media (max-width: 499px) {
  #digital-factory .content {
    padding: 90px 20px 0px 20px;
    overflow-x: hidden;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content {
    padding: 30px 50px 0px 50px;
    overflow-x: hidden;
  }
}
#digital-factory .content .digital-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 499px) {
  #digital-factory .content .digital-main {
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .digital-main .digital-icon {
    display: none;
  }
}
#digital-factory .content .digital-main .digital-icon-mobile {
  display: none;
}
@media (max-width: 499px) {
  #digital-factory .content .digital-main .digital-icon-mobile {
    display: block;
    opacity: 0.21;
    position: absolute;
    right: 0;
    top: 0;
  }
}
#digital-factory .content .digital-main .spaceship {
  position: relative;
  fill: none;
  will-change: transform;
}
#digital-factory .content .digital-main .spaceship .spaceship-shortline {
  stroke-dasharray: 13;
  stroke-dashoffset: 10;
  animation: draw 0.7s ease-in-out infinite;
}
#digital-factory .content .digital-main .spaceship .spaceship-longline {
  stroke-dasharray: 15;
  stroke-dashoffset: 10;
  animation: draw 0.7s ease-in-out infinite;
  animation-delay: 0.08s;
}
#digital-factory .content .digital-main > h1 {
  font-size: 2.92vw;
  letter-spacing: 0.2em;
  margin: 50px 0;
  position: relative;
  top: -25px;
  opacity: 0;
}
#digital-factory .content .digital-main > h1.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .digital-main > h1 {
    font-size: 2.3rem;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .digital-main > h1 {
    font-size: 19px;
    margin: 20px 0;
  }
}
#digital-factory .content .digital-main > div.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .digital-main > div > p {
  font-size: 1.375vw;
  margin-bottom: 25px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .digital-main > div > p {
    font-size: 1.5rem;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .digital-main > div > p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
#digital-factory .content .digital-main .highlights-content-desktop {
  width: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@media (max-width: 499px) {
  #digital-factory .content .digital-main .highlights-content-desktop {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .digital-main .highlights-content-desktop {
    display: none;
  }
}
#digital-factory .content .digital-main .highlights-content-desktop .highlight-container {
  width: 400px;
  padding: 10px;
}
#digital-factory .content .digital-main .group-content-mobile {
  display: none;
  position: relative;
  top: -25px;
  opacity: 0;
}
#digital-factory .content .digital-main .group-content-mobile.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .digital-main .group-content-mobile {
    display: block;
  }
  #digital-factory .content .digital-main .group-content-mobile .swiper {
    width: 450px;
    overflow-x: hidden;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .digital-main .group-content-mobile {
    display: block;
  }
  #digital-factory .content .digital-main .group-content-mobile .swiper {
    overflow-x: hidden;
  }
}
#digital-factory .content .dss-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 499px) {
  #digital-factory .content .dss-section {
    align-items: flex-start;
    margin: 25px 0;
  }
}
#digital-factory .content .dss-section > h2 {
  font-size: 2.27vw;
  letter-spacing: 0.1em;
  margin: 20px 0;
  position: relative;
  top: -25px;
  opacity: 0;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .dss-section > h2 {
    font-size: 1.8rem;
    margin: 25px 0;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .dss-section > h2 {
    font-size: 18px;
  }
}
#digital-factory .content .dss-section > h2.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .dss-section .description {
  position: relative;
  top: -25px;
  opacity: 0;
}
#digital-factory .content .dss-section .description.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .dss-section .description > p {
  font-size: 1.375vw;
  margin-bottom: 25px;
}
@media (max-width: 499px) {
  #digital-factory .content .dss-section .description > p {
    font-size: 16px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .dss-section .description > p {
    font-size: 1.5rem;
  }
}
#digital-factory .content .dss-section .metamorphosis-content {
  display: flex;
  align-items: center;
  margin: 50px 0;
}
@media (max-width: 499px) {
  #digital-factory .content .dss-section .metamorphosis-content {
    flex-direction: column;
    margin: 20px 0;
  }
}
#digital-factory .content .dss-section .metamorphosis-content > img {
  margin-right: 30px;
  position: relative;
  top: -25px;
  opacity: 0;
}
@media (max-width: 499px) {
  #digital-factory .content .dss-section .metamorphosis-content > img {
    width: 120px;
    margin-right: 0;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .dss-section .metamorphosis-content > img {
    width: 120px;
  }
}
#digital-factory .content .dss-section .metamorphosis-content > img.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  top: -25px;
  opacity: 0;
}
@media (max-width: 499px) {
  #digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button {
    margin: 30px 0;
  }
}
#digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button > p {
  font-size: 1.375vw;
  margin-bottom: 25px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button > p {
    font-size: 1.5rem;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button > p {
    font-size: 16px;
    text-align: center;
  }
}
#digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button > button {
  width: 200px;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: bold;
}
@media (max-width: 499px) {
  #digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button > button {
    margin: 0 auto;
  }
}
#digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button > button > a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #021e34;
}
#digital-factory .content .dss-section .metamorphosis-content .metamorphosis-text-button > button > a > img {
  margin-right: 20px;
}
#digital-factory .content .dss-section .dss-advantages-section {
  width: 100%;
}
#digital-factory .content .dss-section .dss-advantages-section .bullet-section {
  font-size: 1.2vw;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .dss-section .dss-advantages-section .bullet-section {
    font-size: 1.5rem;
  }
}
#digital-factory .content .dss-section .dss-advantages-section .bullet-section .bullet-title {
  font-size: 2.27vw;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .dss-section .dss-advantages-section .bullet-section .bullet-title {
    font-size: 1.8rem;
  }
}
#digital-factory .content .dss-section .dss-advantages-section .bullet-section .bullet-content {
  margin-top: 50px;
}
#digital-factory .content .dss-section .dss-advantages-section .bullet-section .bullet-content .bullet-list {
  position: relative;
  top: 40px;
  opacity: 0;
  margin: 15px 0;
}
#digital-factory .content .dss-section .dss-advantages-section .bullet-section .bullet-content .bullet-list.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .dss-section .carousel-section {
  width: 100%;
}
#digital-factory .content .enablers-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section {
    align-items: flex-start;
  }
}
#digital-factory .content .enablers-section > h1 {
  font-size: 2.92vw;
  letter-spacing: 0.2em;
  margin: 20px 0;
  position: relative;
  top: -25px;
  opacity: 0;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section > h1 {
    font-size: 1.8rem;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section > h1 {
    font-size: 18px;
  }
}
#digital-factory .content .enablers-section > h1.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .enablers-section .description {
  margin: 50px 0;
  position: relative;
  top: -25px;
  opacity: 0;
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .description {
    margin: 20px 0;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section .description {
    margin: 20px 0;
  }
}
#digital-factory .content .enablers-section .description.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .enablers-section .description > p {
  font-size: 1.375vw;
  margin-bottom: 25px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section .description > p {
    font-size: 1.5rem;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .description > p {
    font-size: 16px;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-highlights {
    width: 100%;
  }
}
#digital-factory .content .enablers-section .enablers-highlights .enablers-highlight {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  top: -25px;
  left: 0;
  opacity: 0;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight {
    margin-bottom: 100px;
    left: 25px;
  }
}
#digital-factory .content .enablers-section .enablers-highlights .enablers-highlight.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-image-container {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-image-container {
    margin: 25px 0;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-image-container {
    width: auto;
    position: absolute;
    top: -25px;
    left: -25px;
    opacity: 0.21;
  }
}
#digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-text {
  width: 80%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-text > :first-child {
    text-align: center;
  }
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-text > :nth-child(2) {
    font-size: 16px;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-text {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 15px;
  }
}
#digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-text > p {
  font-size: 1.375vw;
  margin-bottom: 25px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-text > p {
    font-size: 1.5rem;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-highlights .enablers-highlight .highlight-text > p {
    font-size: 16px;
    margin-bottom: 5px;
  }
}
#digital-factory .content .enablers-section .enablers-technologies {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-technologies {
    align-items: flex-start;
  }
}
#digital-factory .content .enablers-section .enablers-technologies > h2 {
  font-size: 2.27vw;
  letter-spacing: 0.1em;
  margin: 20px 0;
  position: relative;
  top: -25px;
  opacity: 0;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section .enablers-technologies > h2 {
    font-size: 1.8rem;
  }
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-technologies > h2 {
    font-size: 18px;
  }
}
#digital-factory .content .enablers-section .enablers-technologies > h2.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .enablers-section .enablers-technologies .technologies-container {
  margin: 50px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-technologies .technologies-container {
    display: none;
  }
}
#digital-factory .content .enablers-section .enablers-technologies .technologies-container > a {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  cursor: pointer;
  position: relative;
  top: -25px;
  opacity: 0;
}
#digital-factory .content .enablers-section .enablers-technologies .technologies-container > a.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#digital-factory .content .enablers-section .enablers-technologies .technologies-container > a > img {
  opacity: 0.6;
}
@media (min-width: 500px) and (max-width: 1023px) {
  #digital-factory .content .enablers-section .enablers-technologies .technologies-container > a > img {
    max-width: 150px;
  }
}
#digital-factory .content .enablers-section .enablers-technologies .technologies-container > a > img:hover {
  opacity: 1;
}
#digital-factory .content .enablers-section .enablers-technologies .group-content-mobile {
  display: none;
}
@media (max-width: 499px) {
  #digital-factory .content .enablers-section .enablers-technologies .group-content-mobile {
    display: block;
    overflow-x: auto;
  }
  #digital-factory .content .enablers-section .enablers-technologies .group-content-mobile .swiper .swiper-pagination {
    display: none;
  }
}
#digital-factory .content .enablers-section .enablers-technologies .group-content-mobile .swiper {
  margin-top: 20px;
}
#digital-factory .content .enablers-section .enablers-technologies .group-content-mobile .swiper > a {
  width: 100%;
}
#digital-factory .content .enablers-section .enablers-technologies .group-content-mobile .swiper > a > img {
  width: 100%;
}
#digital-factory .content .enablers-section .enablers-technologies .group-content-mobile .swiper > img {
  width: 100%;
}