.mouse-container {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 5px 0;
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;


}

@media (max-width: 576px) {
    .mouse-container {display: none;}
}

.mouse-container > img {
    cursor: pointer;
    width: 20px;
}