@font-face {
  font-family: "TitilliumWeb-Regular";
  src: local("TitilliumWeb-Regular"), url(../fonts/TitilliumWeb/TitilliumWeb-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "TitilliumWeb-light";
  src: url(../fonts/TitilliumWeb/TitilliumWeb-Light.ttf) format("truetype");
}
@font-face {
  font-family: "TitilliumWeb-ExtraLight";
  src: url(../fonts/TitilliumWeb/TitilliumWeb-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "P2P";
  src: url(../fonts/PressStart/PressStart2P-Regular.ttf) format("truetype");
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 40px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes fadeAboutUs {
  0% {
    opacity: 0;
    top: -25px;
  }
  100% {
    opacity: 1;
    top: -65px;
  }
  100% {
    opacity: 1;
    top: -65px;
  }
}