@import '../../assets/style/style.css';
.success-case-modal__background {
  z-index: 20;
  position: fixed;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 45px);
  background-color: rgba(0, 0, 0, 0.782);
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-case-modal__background .success-case-modal__wrapper {
  padding: 15px;
  position: relative;
  width: 95%;
  height: 95%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .success-case-modal__background .success-case-modal__wrapper {
    flex-direction: column;
  }
}
@media (max-width: 499px) {
  .success-case-modal__background .success-case-modal__wrapper {
    height: 95%;
    width: 95%;
    color: #021e34;
    border-radius: 5px;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-modal-filter {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
}
@media (max-width: 499px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-modal-filter {
    display: none;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-info {
  z-index: 2;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-info {
    width: 100%;
  }
}
@media (max-width: 499px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-info {
    display: none;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-info > h2 {
  letter-spacing: 0.1em;
  font-size: 2.27vw;
  font-weight: bold;
  color: #021e34;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-info > h2 {
    font-size: 1.8rem;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-info .success-case-logo {
  max-height: 150px;
  margin: 50px 0;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-info .success-case-logo {
    max-height: 100px;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-info .success-case-background {
  width: 70%;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-info .success-case-background {
    width: 50%;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-text-container {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.375vw;
  z-index: 3;
  color: #021e34;
  padding-right: 32px;
  padding-top: 32px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container {
    width: 100%;
    padding: 0 50px;
    margin-top: 50px;
    overflow-y: auto;
    font-size: 1.5rem;
  }
}
@media (max-width: 499px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container {
    width: 100%;
    padding: 15px;
    font-size: 14px;
    overflow-y: auto;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-text-container .success-case-mobile-info {
  display: none;
}
@media (max-width: 499px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container .success-case-mobile-info {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container .success-case-mobile-info > span {
    font-size: 18px;
  }
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container .success-case-mobile-info > img {
    max-width: 80%;
  }
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container .success-case-mobile-info > img:first-of-type {
    max-height: 150px;
    width: 100px;
    margin: 15px 0;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-text-container .close-modal-button__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container .close-modal-button__wrapper {
    display: none;
  }
}
@media (max-width: 499px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container .close-modal-button__wrapper {
    display: none;
  }
}
.success-case-modal__background .success-case-modal__wrapper .success-case-text-container .close-modal-button__wrapper .close-desktop-modal__button {
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -32px;
  right: -32px;
}
.success-case-modal__background .success-case-modal__wrapper .success-case-text-container .close-mobile-modal__button {
  display: none;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container .close-mobile-modal__button {
    display: block;
    width: 32px;
    margin: 20px auto;
  }
}
@media (max-width: 499px) {
  .success-case-modal__background .success-case-modal__wrapper .success-case-text-container .close-mobile-modal__button {
    display: block;
    width: 32px;
    margin: 20px auto;
  }
}