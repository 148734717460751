@import '../../../assets/style/style.css';
.research-container {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-self: center;
  margin-top: 30px;
}
.research-container .research-image hr {
  display: none;
}
.research-container .research-text__container {
  display: flex;
  flex-direction: column;
  width: 55%;
  align-items: flex-end;
  justify-content: center;
}
.research-container .research-text__container .research-text {
  text-align: right;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.1vw;
  position: relative;
  top: 40px;
  opacity: 0;
}
.research-container .research-text__container .research-text.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.research-container .research-text__container .research-text .research-text__title {
  text-align: right;
}
.research-container .research-text__container .research-text .research-text__title h1 {
  font-size: 2.92vw;
  letter-spacing: 0.2em;
}
.research-container .research-text__container .research-text .research-text__description {
  font-weight: 400;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 50px;
  font-size: 1.2vw;
}
.research-container .research-text__container .research-page-button {
  display: flex;
  width: 60%;
  justify-content: flex-end;
  position: relative;
  top: 40px;
  opacity: 0;
}
.research-container .research-text__container .research-page-button.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}

@media (max-width: 499px) {
  .research-container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .research-container .research-text__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    width: 80%;
    height: 100%;
  }
  .research-container .research-text__container .research-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 1.1vw;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    height: 100%;
  }
  .research-container .research-text__container .research-text .research-text__title h1 {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .research-container .research-text__container .research-text .research-text__description {
    display: flex;
    align-items: flex-start;
    margin: 0;
    height: 100%;
  }
  .research-container .research-text__container .research-text .research-text__description p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .research-container .research-text__container .research-page-button {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 50px;
  }
  .research-container .research-image {
    display: flex;
    width: 100%;
    height: 30%;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .research-container .research-image > svg {
    width: 200px;
  }
  .research-container .research-image hr {
    display: block;
    color: white;
    position: absolute;
    width: 100%;
    z-index: -1;
    left: 2px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .research-container {
    padding-top: 120px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    margin: 0;
    width: 100%;
    height: 100vh;
  }
  .research-container .research-text__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    width: 80%;
    height: 80%;
  }
  .research-container .research-text__container .research-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 1.1vw;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    height: 80%;
  }
  .research-container .research-text__container .research-text .research-text__title {
    width: 100%;
  }
  .research-container .research-text__container .research-text .research-text__title h1 {
    text-align: center !important;
    font-size: 2.3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .research-container .research-text__container .research-text .research-text__description {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 0;
    height: 100%;
  }
  .research-container .research-text__container .research-text .research-text__description p {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .research-container .research-text__container .research-page-button {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 50px;
  }
  .research-container .research-image {
    display: flex;
    width: 100%;
    height: 30%;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .research-container .research-image > svg {
    width: 200px;
  }
}