@import './assets/style/style.css'; 
body {
  margin: 0;
}

.stop-scrolling {
  overflow: hidden!important;
}

* {
  font-family:  TitilliumWeb-Regular;
  box-sizing: border-box;
}

h1, h2, h3, p {
  margin: 0;
}
