#animated-circle svg {
    width: 110px;
    height: 110px;
  }
  
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #67717b;
    opacity: 0.3;
  }
  
  .CircularProgressbar .CircularProgressbar-path {
    stroke: white !important;
  }
  
  .CircularProgressbar .CircularProgressbar-text {
    fill: white !important;
    font-size: 35px !important;
    font-weight: bolder !important;
  }
  
  .CircularProgressbar-trail {
    stroke-width: 5 !important;
  }
  
  .CircularProgressbar-path {
    stroke-width: 5 !important;
  }