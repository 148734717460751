.line-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    position: fixed;
    top: 45px;
    left:0;
    background: #071c2b;
    z-index: 11;
  }
  .line-wrapper #arrow {
    position: absolute;
  }