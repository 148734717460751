@import '../../assets/style/style.css';
.bullet-section {
  margin: 0;
}
.bullet-section .bullet-title {
  font-size: 1.85vw;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0.1em;
}
@media (max-width: 768px) {
  .bullet-section .bullet-title {
    font-size: 18px !important;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
}
.bullet-section .bullet-content {
  display: flex;
  flex-direction: column;
  list-style-image: url("../../assets/images/subEngineering/focus.svg");
  margin-bottom: 30px;
  padding-left: 23px;
}
@media (max-width: 768px) {
  .bullet-section .bullet-content > li > header {
    font-size: 16px;
  }
}
.bullet-section .bullet-content > li > p {
  font-size: 1.2vw;
}