@import '../../assets/style/style.css';
#gaming-page {
  font-family: P2P;
}
#gaming-page h1, #gaming-page h2, #gaming-page h3, #gaming-page p, #gaming-page span {
  font-family: P2P;
}
#gaming-page text {
  font-family: P2P;
  font-size: 26px;
  letter-spacing: 0.22em;
}
#gaming-page text.animate {
  animation: pulseAnimation 2s ease-in-out infinite;
}
#gaming-page text.remove {
  display: none;
}
#gaming-page .dragged {
  animation: coinRotating 0.2s ease-in-out forwards;
}
#gaming-page .drag-coin-hint {
  position: relative;
  animation: dragCoinHint 1.5s ease-in-out infinite;
}
#gaming-page .drag-coin-hint.remove {
  display: none;
}

#gaming-page__mobile {
  display: none;
}

@media (max-width: 499px) {
  #gaming-page {
    display: none;
  }
  #gaming-page__mobile {
    display: block;
    font-family: P2P;
  }
  #gaming-page__mobile h1, #gaming-page__mobile h2, #gaming-page__mobile h3, #gaming-page__mobile p, #gaming-page__mobile span {
    font-family: P2P;
  }
  #gaming-page__mobile text {
    font-family: P2P;
    font-size: 26px;
    letter-spacing: 0.22em;
  }
  #gaming-page__mobile text.animate {
    animation: pulseAnimation 2s ease-in-out infinite;
  }
  #gaming-page__mobile text.remove {
    display: none;
  }
  #gaming-page__mobile .dragged {
    animation: coinRotating 0.2s ease-in-out forwards;
  }
  #gaming-page__mobile .drag-coin-hint {
    position: relative;
    animation: dragCoinHintMobile 1.5s ease-in-out infinite;
  }
  #gaming-page__mobile .drag-coin-hint.remove {
    display: none;
  }
  #gaming-page__mobile #joystick-ball {
    fill: rgb(200, 47, 67);
  }
  #gaming-page__mobile #joystick-stick {
    fill: rgb(164, 164, 164);
  }
}
@keyframes pulseAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes coinRotating {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 90deg;
  }
}
@keyframes dragCoinHint {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(120px, 120px);
  }
}
@keyframes dragCoinHintMobile {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(120px, 160px);
  }
}