@import "../../../assets/style/style.css";
.innovation-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .innovation-container {
    flex-direction: column;
  }
}
.innovation-container .innovation-image {
  z-index: 10;
  display: flex;
  height: 400px;
  width: 400px;
  justify-content: center;
  align-items: center;
}
.innovation-container .innovation-image hr {
  display: none;
}
.innovation-container .innovation-image svg path.line, .innovation-container .innovation-image svg line.line {
  fill: none;
}
.innovation-container .innovation-image .spaceship {
  position: relative;
  fill: none;
  will-change: transform;
}
@media (min-width: 1024px) {
  .innovation-container .innovation-image .spaceship {
    top: 70%;
    left: -100%;
  }
  .innovation-container .innovation-image .spaceship.animate {
    animation: spaceshipAppearing 0.9s ease-in-out forwards;
  }
}
.innovation-container .innovation-image .spaceship .spaceship-shortline {
  stroke-dasharray: 13;
  stroke-dashoffset: 10;
  animation: draw 0.7s ease-in-out infinite;
}
.innovation-container .innovation-image .spaceship .spaceship-longline {
  stroke-dasharray: 15;
  stroke-dashoffset: 10;
  animation: draw 0.7s ease-in-out infinite;
  animation-delay: 0.08s;
}
.innovation-container .innovation-text__container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 50%;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .innovation-container .innovation-text__container {
    width: 80%;
    justify-content: flex-start;
    align-items: center;
  }
}
.innovation-container .innovation-text {
  text-align: left;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.1vw;
  position: relative;
  top: 40px;
  opacity: 0;
}
.innovation-container .innovation-text.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .innovation-container .innovation-text {
    justify-content: flex-start;
    height: 80%;
  }
  .innovation-container .innovation-text .innovation-text__title > h1 {
    margin: 20px 0;
    text-align: center;
    font-size: 2.3rem;
  }
  .innovation-container .innovation-text .innovation-text__description {
    font-size: 1.5rem;
  }
}
.innovation-container .innovation-text h1 {
  font-size: 2.92vw;
  letter-spacing: 0.2em;
}
.innovation-container .innovation-text__description {
  display: flex;
  flex-direction: column;
  height: 75%;
  justify-content: space-between;
  font-size: 1.2vw;
}
.innovation-container .innovation-page-button {
  display: flex;
  width: 55%;
  position: relative;
  top: 40px;
  opacity: 0;
}
.innovation-container .innovation-page-button.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .innovation-container .innovation-page-button {
    margin: 25px 0;
    width: 90%;
    justify-content: center;
  }
}

@keyframes spaceshipAppearing {
  0% {
    top: 70%;
    left: -100%;
  }
  80% {
    top: -10%;
    left: 10%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes draw {
  0% {
    stroke-dashoffset: 7;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 7;
  }
}
@media (max-width: 499px) {
  .spaceship {
    fill: #091f3a !important;
  }
  .innovation-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .innovation-container .innovation-image {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 280px;
  }
  .innovation-container .innovation-image > svg {
    width: 200px;
  }
  .innovation-container .innovation-image .image-animation {
    display: none;
  }
  .innovation-container .innovation-image hr {
    display: flex;
    width: 100%;
    position: absolute;
    margin-top: 17px;
    z-index: -1;
    color: white;
    width: 100%;
  }
  .innovation-container .innovation-text__container {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
  }
  .innovation-container .innovation-text__container .innovation-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 1.1vw;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
  .innovation-container .innovation-text__container .innovation-text .innovation-text__title {
    width: 100%;
  }
  .innovation-container .innovation-text__container .innovation-text .innovation-text__title h1 {
    font-size: 19px;
    margin-bottom: 20px;
  }
  .innovation-container .innovation-text__container .innovation-text .innovation-text__description {
    justify-content: space-evenly;
    margin-bottom: -20px;
  }
  .innovation-container .innovation-text__container .innovation-text .innovation-text__description p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .innovation-container .innovation-text__container .innovation-page-button {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 50px;
  }
}