.carousel-container {
    width: 100%;
    height: 200px;
    text-align: center;
  }
  .carousel-container > span {
    font-size: 2.92vw;
  }

  @media (min-width: 500px) and (max-width: 1023px){
    .carousel-container > span {
      font-size: 32px;
    }
  }

  @media (max-width: 768px) {

    .carousel-container > span {
      font-size: 20px;
    }
  }
  .carousel-container .slick-slider {
    margin-top: 20px;
    background-color: #fff;
  }
  .carousel-container .slick-list {
    height: 157px;
    display: flex;
    align-items: center;
  }
  .carousel-container .slick-list .slick-track {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .carousel-container .carousel-item a > img {
    margin: 0 auto;
    width: 150px;
    cursor: pointer;
  }