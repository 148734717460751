@import '../../assets/style/style.css';
.digital-carousel-container {
  width: 100%;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
.digital-carousel-container > h2 {
  font-size: 2.27vw;
  letter-spacing: 0.1em;
}
@media (max-width: 499px) {
  .digital-carousel-container > h2 {
    font-size: 18px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .digital-carousel-container > h2 {
    font-size: 1.8rem;
  }
}
.digital-carousel-container > span {
  font-size: 2.27vw;
  letter-spacing: 0.1em;
  position: relative;
  top: -25px;
  opacity: 0;
}
.digital-carousel-container > span.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
@media (max-width: 499px) {
  .digital-carousel-container > span {
    font-size: 18px;
  }
}
.digital-carousel-container > .slick-slider {
  width: 100%;
  margin-top: 20px;
}
.digital-carousel-container > .slick-slider > .slick-next, .digital-carousel-container > .slick-slider .slick-prev {
  display: none !important;
}
.digital-carousel-container > .slick-slider > .slick-list {
  display: flex;
  align-items: center;
}
.digital-carousel-container > .slick-slider > .slick-list .slick-track {
  display: flex;
}
.digital-carousel-container > .slick-slider > .slick-list .slick-slide {
  margin: 0 25px;
}
.digital-carousel-container > .slick-slider .slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}
.digital-carousel-container > .slick-slider .slick-dots > li.slick-active > button {
  background: #fff;
}
.digital-carousel-container > .slick-slider .slick-dots > li button {
  margin: 0 5px;
  font-size: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid white;
  cursor: pointer;
}
.digital-carousel-container .digital-carousel-item {
  text-align: center;
  position: relative;
  top: -25px;
  opacity: 0;
}
.digital-carousel-container .digital-carousel-item.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
@media (min-width: 1024px) {
  .digital-carousel-container .digital-carousel-item:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}
.digital-carousel-container .digital-carousel-item .item-title {
  position: absolute;
  top: 15%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  letter-spacing: 0.2em;
  font-size: 14px;
}
@media (max-width: 499px) {
  .digital-carousel-container .digital-carousel-item .item-title {
    top: 15px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .digital-carousel-container .digital-carousel-item .item-title {
    font-size: 12px;
  }
}
.digital-carousel-container .digital-carousel-item .item-background {
  width: 95%;
  opacity: 0.6;
}
@media (max-width: 499px) {
  .digital-carousel-container .digital-carousel-item .item-background {
    height: 180px;
    width: 100%;
  }
}
.digital-carousel-container .digital-carousel-item .item-logo {
  position: absolute;
  top: 8%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 75px;
}
@media (max-width: 499px) {
  .digital-carousel-container .digital-carousel-item .item-logo {
    top: 15px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .digital-carousel-container .digital-carousel-item .item-logo {
    max-height: 30px;
  }
}