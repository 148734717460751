.logo-card {
  display: flex;
  width: 38vh;
  height: 36vh;
  background-color: white;
  border-radius: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .logo-card {
    width: 43vh;
    height: 40vh;
  }
}
.logo-card figure {
  display: flex;
  align-items: center;
  height: 40%;
  background-color: white;
}
.logo-card figure > img {
  display: flex;
  width: 100%;
  height: 100px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .logo-card figure > img {
    height: 180px;
    margin: auto;
  }
}
.logo-card .card-bottom {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  height: 50%;
  width: 100%;
  background-color: #eef2f3;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  justify-content: center;
  text-align: start;
}
.logo-card .card-bottom > p {
  width: 100%;
  font-weight: bold;
  text-indent: 8%;
  font-size: 2.1vh;
  color: #021e34;
}
.logo-card .card-bottom > hr {
  width: 80%;
  color: rgba(84, 84, 88, 0.5019607843);
}
.logo-card .card-bottom .link-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
}
.logo-card .card-bottom .link-container > a {
  display: flex;
  width: 45%;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #021e34;
  text-decoration: none;
  font-weight: bold;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .logo-card .card-bottom .link-container > a {
    width: auto;
  }
}
.logo-card .card-bottom .link-container > a > p {
  margin-bottom: 2px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .logo-card .card-bottom .link-container > a > p {
    margin-left: 15px;
  }
}