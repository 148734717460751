.footer-container {
  text-align: center;
  background-color: white;
}
.footer-container .desktop-footer {
  position: relative;
  top: 45px;
  background-color: rgba(255, 255, 255, 0.966);
  color: #021e34;
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.footer-container .desktop-footer .logo-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-container .desktop-footer .logo-container .footer-line {
  width: 35%;
  border-bottom: 2px solid #021e34;
}
.footer-container .desktop-footer .logo-container > img {
  width: 7vw;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .footer-container .desktop-footer .logo-container > img {
    width: 100px;
  }
}
.footer-container .desktop-footer .content-container {
  width: 75%;
  display: flex;
  justify-content: space-between;
}
.footer-container .desktop-footer .content-container p {
  color: #021e34;
}
.footer-container .desktop-footer .content-container .info-container {
  width: 33%;
  text-align: left;
}
.footer-container .desktop-footer .content-container .social-container {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-container .desktop-footer .content-container .social-container > a > img {
  cursor: pointer;
  width: 2vw;
  height: 3vh;
}
.footer-container .desktop-footer .content-container .terms-container {
  width: 33%;
  text-align: right;
}
.footer-container .desktop-footer .content-container .terms-container a,
.footer-container .desktop-footer .content-container .terms-container span {
  text-decoration: none;
  color: #021e34;
}
.footer-container .desktop-footer .footer-copyright {
  padding: 10px 0;
  opacity: 0.8;
}
.footer-container .mobile-footer {
  display: none;
}

@media (max-width: 499px) {
  .footer-container {
    position: relative;
    color: white;
    z-index: 4;
  }
  .footer-container .desktop-footer {
    display: none;
  }
  .footer-container .mobile-footer {
    background: white;
    position: relative;
    top: 45px;
    color: #021e34;
    width: 100%;
    height: 240px;
    display: flex;
    padding: 20px 35px 0px 35px;
    flex-direction: column;
    justify-content: space-evenly;
    color: #021e34;
  }
  .footer-container .mobile-footer .seguiSocial {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .footer-container .mobile-footer .seguiSocial .line {
    width: 26%;
    height: 1px;
    background-color: black;
  }
  .footer-container .mobile-footer .iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .footer-container .mobile-footer .line {
    height: 1px;
    background-color: black;
  }
  .footer-container .mobile-footer .box-informazioni {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-container .mobile-footer .box-informazioni a {
    text-decoration: none;
  }
  .footer-container .mobile-footer .box-informazioni span {
    font-size: 15px;
  }
  .footer-container .mobile-footer .box-informazioni .left-box {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .footer-container .mobile-footer .box-informazioni .right-box {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
  .footer-container .mobile-footer .footer-copyright p {
    color: #808f99;
  }
}