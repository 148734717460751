@import '../../../assets/style/style.css';
#research-development .line-wrapper {
  display: none;
}
@media (min-width: 1024px) {
  #research-development .line-wrapper {
    display: block;
  }
}
#research-development .content {
  position: relative;
  top: 130px;
  margin-bottom: 130px;
  display: flex;
  flex-direction: column;
  padding: 20px 200px 0px 200px;
  overflow-y: hidden;
}
@media (max-width: 499px) {
  #research-development .content {
    top: 0;
    margin-bottom: 0;
    width: 100%;
    padding: 90px 20px 0px 20px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #research-development .content {
    top: 50px;
    padding: 20px 50px;
  }
}
#research-development .content .red-main {
  position: relative;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 499px) {
  #research-development .content .red-main {
    align-items: flex-start;
    height: auto;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #research-development .content .red-main {
    height: auto;
  }
}
#research-development .content .red-main .mobile-icon {
  display: none;
}
@media (max-width: 499px) {
  #research-development .content .red-main .mobile-icon {
    display: block;
    width: 150px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.21;
  }
}
@media (max-width: 499px) {
  #research-development .content .red-main > svg {
    display: none;
  }
}
#research-development .content .red-main > h1 {
  margin: 50px 0;
  font-size: 2.92vw;
  letter-spacing: 0.2em;
  position: relative;
  top: -25px;
  opacity: 0;
}
@media (max-width: 499px) {
  #research-development .content .red-main > h1 {
    font-size: 19px;
    margin: 20px 0;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #research-development .content .red-main > h1 {
    font-size: 2.3rem;
  }
}
#research-development .content .red-main > h1.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#research-development .content .red-main > div {
  position: relative;
  top: -25px;
  opacity: 0;
}
#research-development .content .red-main > div.fade-once {
  animation: fadeIn 0.6s ease-in-out forwards;
}
#research-development .content .red-main > div > p {
  margin-bottom: 25px;
  font-size: 1.375vw;
}
@media (max-width: 499px) {
  #research-development .content .red-main > div > p {
    font-size: 16px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #research-development .content .red-main > div > p {
    font-size: 1.5rem;
  }
}
#research-development .content .red-second-section {
  margin: 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 499px) {
  #research-development .content .red-second-section {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #research-development .content .red-second-section {
    display: none;
  }
}
#research-development .content .red-second-section .subtitle-animation-container {
  display: flex;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 2rem;
  letter-spacing: 0.3rem;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content .subtitle-first {
  position: relative;
  opacity: 0;
  left: -100%;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content .subtitle-first.animate {
  animation: fadeFromLeft 0.9s ease-in-out forwards 0.1s;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content .subtitle-second {
  position: relative;
  opacity: 0;
  left: -100%;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content .subtitle-second.animate {
  animation: fadeFromLeft 0.9s ease-in-out forwards 0.19s;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content .subtitle-third {
  position: relative;
  opacity: 0;
  left: -100%;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content .subtitle-third.animate {
  animation: fadeFromLeft 0.9s ease-in-out forwards 0.28s;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content .subtitle-fourth {
  position: relative;
  opacity: 0;
  left: -100%;
}
#research-development .content .red-second-section .subtitle-animation-container .subtitle-text-content .subtitle-fourth.animate {
  animation: fadeFromLeft 0.9s ease-in-out forwards 0.37s;
}
#research-development .content .red-second-section .description-second-section {
  height: 100%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
#research-development .content .red-second-section .description-second-section .description-first-part, #research-development .content .red-second-section .description-second-section .description-second-part {
  margin: 20px;
  display: flex;
  align-items: center;
}
#research-development .content .red-second-section .description-second-section .description-first-part .first-sentence, #research-development .content .red-second-section .description-second-section .description-second-part .first-sentence {
  position: relative;
  opacity: 0;
  left: -45%;
}
#research-development .content .red-second-section .description-second-section .description-first-part .first-sentence.animate, #research-development .content .red-second-section .description-second-section .description-second-part .first-sentence.animate {
  animation: fadeFirstSentenceFromLeft 1.5s ease-in-out forwards 0.9s;
}
#research-development .content .red-second-section .description-second-section .description-first-part .second-sentence, #research-development .content .red-second-section .description-second-section .description-second-part .second-sentence {
  position: relative;
  opacity: 0;
  left: -10%;
}
#research-development .content .red-second-section .description-second-section .description-first-part .second-sentence.animate, #research-development .content .red-second-section .description-second-section .description-second-part .second-sentence.animate {
  animation: fadeSecondSentenceFromLeft 1.5s ease-in-out forwards 1.8s;
}
#research-development .content .red-second-section .description-second-section .description-first-part span, #research-development .content .red-second-section .description-second-section .description-second-part span {
  font-size: 1.56rem;
  letter-spacing: 0.1rem;
}
#research-development .content .red-second-section .description-second-section .description-first-part > img, #research-development .content .red-second-section .description-second-section .description-second-part > img {
  margin: 0 20px;
  position: relative;
  opacity: 0;
  left: -45%;
}
#research-development .content .red-second-section .description-second-section .description-first-part > img.animate, #research-development .content .red-second-section .description-second-section .description-second-part > img.animate {
  animation: moveFromLeft 1.5s ease-in-out forwards 1s;
}
#research-development .content .red-second-section__mobile {
  display: none;
}
@media (max-width: 499px) {
  #research-development .content .red-second-section__mobile {
    display: block;
    position: relative;
    top: -25px;
    opacity: 0;
  }
  #research-development .content .red-second-section__mobile.fade-once {
    animation: fadeIn 0.6s ease-in-out forwards;
  }
  #research-development .content .red-second-section__mobile > :nth-child(5) > .mobile-arrow-container {
    transform: scaleX(-1);
  }
  #research-development .content .red-second-section__mobile > p {
    text-align: center;
    font-size: 19px;
    margin: 15px 0;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper {
    margin-bottom: 50px;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper .mobile-section-string__container {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -25px;
    opacity: 0;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper .mobile-section-string__container.animate {
    animation: fadeIn 0.6s ease-in-out forwards;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper .mobile-section-string__container > img {
    margin: 0 10px;
  }
  #research-development .content .red-second-section__mobile .mobile-arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -25px;
    opacity: 0;
  }
  #research-development .content .red-second-section__mobile .mobile-arrow-container.animate {
    animation: fadeIn 0.6s ease-in-out forwards;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  #research-development .content .red-second-section__mobile {
    display: block;
    position: relative;
    top: -25px;
    opacity: 0;
  }
  #research-development .content .red-second-section__mobile.fade-once {
    animation: fadeIn 0.6s ease-in-out forwards;
  }
  #research-development .content .red-second-section__mobile > :nth-child(5) > .mobile-arrow-container {
    transform: scaleX(-1);
  }
  #research-development .content .red-second-section__mobile > p {
    text-align: center;
    font-size: 1.5rem;
    margin: 15px 0;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper {
    margin-bottom: 50px;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper .mobile-section-string__container {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -25px;
    opacity: 0;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper .mobile-section-string__container.animate {
    animation: fadeIn 0.6s ease-in-out forwards;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper .mobile-section-string__container > img {
    margin: 0 10px;
  }
  #research-development .content .red-second-section__mobile .mobile-section-string-wrapper .mobile-section-string__container > span {
    font-size: 1.5rem;
  }
  #research-development .content .red-second-section__mobile .mobile-arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -25px;
    opacity: 0;
  }
  #research-development .content .red-second-section__mobile .mobile-arrow-container.animate {
    animation: fadeIn 0.6s ease-in-out forwards;
  }
}

@keyframes fadeFromLeft {
  0% {
    opacity: 0;
    left: -100%;
  }
  65% {
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes fadeFirstSentenceFromLeft {
  0% {
    opacity: 0;
    left: -45%;
  }
  35% {
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes fadeSecondSentenceFromLeft {
  0% {
    opacity: 0;
    left: -10%;
  }
  45% {
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes moveFromLeft {
  0% {
    opacity: 0;
    left: -45%;
  }
  35% {
    opacity: 1;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}