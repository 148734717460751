@import '../../assets/style/style.css';
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes spin3D {
  from {
    transform: rotate3d(0.5, 0.5, 0.5, 360deg);
  }
  to {
    transform: rotate3d(0deg);
  }
}
.spinner-box {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #021e34;
}
.spinner-box > span {
  position: absolute;
}

/* SOLAR SYSTEM */
.solar-system {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #fafbfC;
  border-radius: 50%;
}

.earth-orbit {
  width: 165px;
  height: 165px;
  animation: spin 12s linear 0s infinite;
}

.venus-orbit {
  width: 125px;
  height: 125px;
  animation: spin 7.4s linear 0s infinite;
}

.mercury-orbit {
  width: 90px;
  height: 90px;
  animation: spin 6s linear 0s infinite;
}

.planet {
  position: absolute;
  top: -7.5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
}

.sun {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ffab91;
}

.leo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.blue-orbit {
  width: 165px;
  height: 165px;
  border: 1px solid rgba(145, 218, 255, 0.6470588235);
  animation: spin3D 3s linear 0.2s infinite;
}

.green-orbit {
  width: 120px;
  height: 120px;
  border: 1px solid rgba(145, 255, 191, 0.6470588235);
  animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
  width: 90px;
  height: 90px;
  border: 1px solid rgba(255, 202, 145, 0.6470588235);
  animation: spin3D 1s linear 0s infinite;
}

.white-orbit {
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  animation: spin3D 10s linear 0s infinite;
}