@import '../../../assets/style/style.css';
.engineering-container {
  align-self: flex-start;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin-left: 8.5%;
  height: 100%;
}
.engineering-container .engineering-text__container {
  display: flex;
  flex-direction: column;
  height: 80%;
  text-align: left;
  justify-content: center;
  width: 70%;
  margin-bottom: 30px;
}
.engineering-container .engineering-text__container .engineering-text {
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.1vw;
  position: relative;
  top: 40px;
  opacity: 0;
}
.engineering-container .engineering-text__container .engineering-text.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.engineering-container .engineering-text__container .engineering-text .engineering-text__title {
  text-align: left;
}
.engineering-container .engineering-text__container .engineering-text .engineering-text__title h1 {
  font-size: 2.92vw;
  letter-spacing: 0.2em;
}
.engineering-container .engineering-text__container .engineering-text .engineering-text__description {
  display: flex;
  flex-direction: column;
  height: 55%;
  justify-content: space-between;
  width: 75%;
  font-size: 1.2vw;
}
.engineering-container .engineering-text__container .engineering-page-button {
  display: flex;
  width: 55%;
  margin-top: 50px;
  position: relative;
  top: 40px;
  opacity: 0;
}
.engineering-container .engineering-text__container .engineering-page-button.animate {
  animation: fadeIn 0.6s ease-in-out forwards;
}
.engineering-container .engineering-image .gear {
  transform: rotate(0deg);
}
.engineering-container .engineering-image .gear.animate {
  animation: gearRotating 5s infinite;
}
.engineering-container .engineering-image hr {
  display: none;
}
@keyframes gearRotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 499px) {
  .engineering-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .engineering-container .engineering-text__container {
    align-items: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .engineering-container .engineering-text__container .engineering-text {
    align-items: center;
    display: flex;
    font-size: 1.1vw;
    height: 100%;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .engineering-container .engineering-text__container .engineering-text .engineering-text__title {
    align-self: flex-start;
    margin-left: 12.3%;
  }
  .engineering-container .engineering-text__container .engineering-text .engineering-text__title h1 {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .engineering-container .engineering-text__container .engineering-text .engineering-text__description {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
  .engineering-container .engineering-text__container .engineering-text .engineering-text__description p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .engineering-container .engineering-text__container .engineering-page-button {
    display: flex;
    justify-content: center;
    width: 80%;
  }
  .engineering-container .engineering-image {
    display: flex;
    width: 100%;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .engineering-container .engineering-image > svg {
    width: 200px;
  }
  .engineering-container .engineering-image hr {
    display: flex;
    color: white;
    position: absolute;
    width: 100%;
    z-index: -1;
    left: 2px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .engineering-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .engineering-container .engineering-text__container {
    align-items: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .engineering-container .engineering-text__container .engineering-text {
    align-items: center;
    display: flex;
    font-size: 1.1vw;
    height: 100%;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .engineering-container .engineering-text__container .engineering-text .engineering-text__title {
    width: 100%;
    align-self: flex-start;
    text-align: center;
  }
  .engineering-container .engineering-text__container .engineering-text .engineering-text__title h1 {
    font-size: 2.3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .engineering-container .engineering-text__container .engineering-text .engineering-text__description {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
  .engineering-container .engineering-text__container .engineering-text .engineering-text__description p {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .engineering-container .engineering-text__container .engineering-page-button {
    display: flex;
    justify-content: center;
    width: 80%;
  }
  .engineering-container .engineering-image {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .engineering-container .engineering-image > svg {
    width: 250px;
  }
}