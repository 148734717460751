@import '../../assets/style/style.css';
/* 1 SECTION  */
.home-container {
  z-index: 10;
  position: relative;
  width: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-container .linea {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600vh;
}
.home-container .arrow {
  z-index: 10;
}
.home-container .line-container {
  width: 100%;
  height: 100vh;
  position: absolute;
}
.home-container .arrow-container {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.home-container .content {
  z-index: 10;
  height: 90%;
  width: 100%;
  display: flex;
}
.home-container .content .byz-container {
  z-index: 10;
  height: 100%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.home-container .content .byz-container svg {
  height: 380px;
}
.home-container .content .byz-container .image-desktop svg {
  height: 450px;
  margin-bottom: 100px;
}
.home-container .content .text-container {
  z-index: 10;
  color: white;
  font-size: x-large;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.home-container .content .text-container #gray-span {
  color: #9c9c9c;
}

/*  2 SECTION  */
.innovation-section {
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  z-index: 10;
}

.cyber-section,
.engineering-section,
.red-section,
.partners-section {
  position: relative;
  z-index: 10;
  color: white;
  width: calc(100% - 5px);
  height: calc(100vh - 5px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partners-section {
  justify-content: space-evenly !important;
}

.title-wrapper {
  opacity: 0;
  line-height: 60px;
  position: relative;
  animation: titleAppearing 1s ease-in-out forwards 0.7s;
  margin-bottom: 10px;
  font-size: 2.92vw;
  font-weight: bold;
  top: -30px;
}
.title-wrapper > span {
  letter-spacing: 1vw;
}

.description-wrapper-desktop {
  width: 70%;
  opacity: 0;
  position: relative;
  animation: titleAppearing 1s ease-in-out forwards 0.7s;
  font-size: 1.375vw;
  top: -30px;
}

.description-wrapper-mobile {
  display: none;
}

@keyframes titleAppearing {
  0% {
    opacity: 0;
    left: -200px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@media (max-width: 499px) {
  .linea,
#arrow,
.mouse-container {
    display: none;
  }
  .full-background {
    display: flex;
    flex-direction: column;
    background-color: #091f3a !important;
    background-image: none !important;
  }
  .full-background .home-container {
    display: flex;
    flex-direction: column;
    margin-bottom: -150px;
  }
  .full-background .home-container .content {
    display: block;
  }
  .full-background .home-container .content .title-wrapper {
    font-size: 19px;
  }
  .full-background .home-container .content .byz-container {
    width: 100%;
    height: 65%;
    margin-top: 20px;
  }
  .full-background .home-container .content .image-desktop svg {
    height: 360px;
    margin-bottom: 0;
  }
  .full-background .home-container .content .text-container {
    width: 100%;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
  .full-background .home-container .content .text-container .title-wrapper {
    top: 0;
    margin-bottom: 0;
    line-height: 40px;
    animation: titleAppearing 0s ease-in-out forwards 0s;
    text-align: center;
  }
  .full-background .home-container .content .text-container .title-wrapper .title-first-row,
.full-background .home-container .content .text-container .title-wrapper .title-second-row {
    font-size: x-large;
    justify-content: center;
  }
  .full-background .home-container .content .text-container .description-wrapper-desktop {
    display: none;
  }
  .full-background .home-container .content .text-container .description-wrapper-mobile {
    display: contents;
    font-size: 18px;
  }
  .full-background .innovation-section,
.full-background .cyber-section,
.full-background .engineering-section,
.full-background .red-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: initial;
    height: 100%;
    margin: 0;
    margin-top: 80px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .linea,
#arrow,
.mouse-container {
    display: none;
  }
  .full-background {
    display: flex;
    flex-direction: column;
    background-color: #091f3a !important;
    background-image: none !important;
  }
  .full-background .home-container {
    display: flex;
    flex-direction: column;
  }
  .full-background .home-container .content {
    display: block;
  }
  .full-background .home-container .content .title-wrapper {
    font-size: 19px;
  }
  .full-background .home-container .content .byz-container {
    width: 100%;
    height: 50%;
    margin-top: 20px;
  }
  .full-background .home-container .content .image-desktop svg {
    height: 360px;
    margin-bottom: 0;
  }
  .full-background .home-container .content .text-container {
    height: 50%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
  .full-background .home-container .content .text-container .title-wrapper {
    top: 0;
    margin-bottom: 80px;
    line-height: 70px;
    animation: titleAppearing 0s ease-in-out forwards 0s;
    text-align: center;
    font-size: 2.3rem;
  }
  .full-background .home-container .content .text-container .title-wrapper > span {
    margin-bottom: 5px;
  }
  .full-background .home-container .content .text-container .description-wrapper-desktop {
    font-size: 1.5rem;
    width: 80%;
    display: block;
  }
  .full-background .home-container .content .text-container .description-wrapper-mobile {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 1023px) and (min-width: 500px) and (max-width: 1023px) {
  .full-background .innovation-section {
    margin-top: 120px !important;
  }
}
@media (min-width: 500px) and (max-width: 1023px) {
  .full-background .innovation-section,
.full-background .cyber-section,
.full-background .engineering-section,
.full-background .red-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: initial;
    height: 100%;
    margin: 0;
    margin-top: 80px;
  }
}
@media (min-width: 500px) and (max-width: 1023px) and (min-width: 500px) and (max-width: 1023px) {
  .full-background .innovation-section,
.full-background .cyber-section,
.full-background .engineering-section,
.full-background .red-section {
    margin-top: 0;
  }
}