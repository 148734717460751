@import '../../assets/style/style.css';
.cookies-modal-background {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(4, 20, 35, 0.8078431373);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cookies-modal-background .cookies-modal-content__wrapper {
  border-radius: 20px;
  position: relative;
  width: 50%;
  background-color: #fff;
  color: #021e34;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px 100px;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .cookies-modal-background .cookies-modal-content__wrapper {
    width: 70%;
    padding: 35px;
  }
}
@media (min-width: 1024px) {
  .cookies-modal-background .cookies-modal-content__wrapper {
    max-height: 450px;
  }
}
@media (max-width: 499px) {
  .cookies-modal-background .cookies-modal-content__wrapper {
    width: 90%;
    height: 90%;
    padding: 30px 15px;
  }
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-policy-text {
  overflow-y: auto;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .cookies-modal-background .cookies-modal-content__wrapper .cookies-policy-text {
    max-height: 500px;
  }
}
@media (max-width: 499px) {
  .cookies-modal-background .cookies-modal-content__wrapper .cookies-policy-text {
    padding: 30px 10px;
  }
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-policy-text::-webkit-scrollbar {
  width: 5px;
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-policy-text::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.723);
  border-radius: 5px;
}
.cookies-modal-background .cookies-modal-content__wrapper .byz-cookies {
  width: 150px;
  position: absolute;
  top: 15%;
  left: -85px;
}
@media (max-width: 499px) {
  .cookies-modal-background .cookies-modal-content__wrapper .byz-cookies {
    height: 150px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.cookies-modal-background .cookies-modal-content__wrapper .close-cookies-modal__button {
  cursor: pointer;
  width: 24px;
  position: absolute;
  right: 12px;
  top: 12px;
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
@media (min-width: 500px) and (max-width: 1023px) {
  .cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content {
    padding-left: 50px;
  }
}
@media (max-width: 499px) {
  .cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-text {
    text-align: center;
  }
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-text .cookies-modal-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 27px;
  letter-spacing: 0.25em;
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-text .cookies-modal-description {
  margin-top: 10px;
  font-size: 1.0625rem;
  line-height: 26px;
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-text .cookies-modal-description > a {
  text-decoration: none;
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
@media (max-width: 499px) {
  .cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-buttons {
    justify-content: space-evenly;
  }
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-buttons > button {
  cursor: pointer;
  margin-left: 25px;
  width: 125px;
  padding: 5px;
  border: 1px solid #021e34;
  border-radius: 5px;
  letter-spacing: 0.5px;
}
@media (max-width: 499px) {
  .cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-buttons > button {
    margin-left: 0;
  }
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-buttons .accept-cookies {
  background-color: #021e34;
  color: white;
}
.cookies-modal-background .cookies-modal-content__wrapper .cookies-modal-content .cookies-modal-buttons .refuse-cookies {
  background-color: #fff;
  color: #021e34;
}